import { Offcanvas } from "react-bootstrap";

const Checkbox = ({ id, name, value, label, disabled, checked, onChange, register }) => (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id={id}
        name={name}
        value={value}
        {...register(name)}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
  
  const FilingProcessOffcanvas = ({ fillingProccessShow, handleCloseFillingProccess, handleSubmit1, serviceUpdate, register1, visaApprovedChecked, visaRefusalChecked, handleVisaApprovedChange, handleVisaRefusalChange }) => (
    <Offcanvas show={fillingProccessShow} onHide={handleCloseFillingProccess} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Filing Process</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <form onSubmit={handleSubmit1(serviceUpdate)}>
          <span className="col-12">Require Document</span>
  
          <Checkbox
            id="caseInitiated"
            name="caseInitiated"
            value="Case Initiated"
            label="Case Initiated"
            register={register1}
          />
  
          <Checkbox
            id="fileIntiated"
            name="fileIntiated"
            value="File Initiated or docs checklist sent"
            label="File Initiated or docs checklist sent"
            register={register1}
            disabled={true}
          />
  
          <Checkbox
            id="docsReceived"
            name="docsReceived"
            value="Docs received & Pending docs sent"
            label="Docs received & Pending docs sent"
            register={register1}
            disabled={true}
          />
  
          <Checkbox
            id="sopprepration"
            name="sopprepration"
            value="Sop Or letters prepration & Forms prep"
            label="SOP Or letters prepration & Forms prep"
            register={register1}
            disabled={true}
          />
  
          <Checkbox
            id="sopletters"
            name="sopletters"
            value="SOP or Letters sent to client"
            label="SOP or Letters sent to client"
            register={register1}
            disabled={true}
          />
  
          <Checkbox
            id="confirmrecieved"
            name="confirmrecieved"
            value="Confirmation received on SOP, letters and docs, Forms confirmation"
            label="Confirmation received on SOP, letters and docs, Forms confirmation"
            register={register1}
            disabled={true}
          />
  
          <Checkbox
            id="filesent"
            name="filesent"
            value="File sent for Upload or submission"
            label="File sent for Upload or submission"
            register={register1}
            disabled={true}
          />
  
          <Checkbox
            id="filessubmitted"
            name="filessubmitted"
            value="Files Submitted"
            label="Files Submitted"
            register={register1}
            disabled={true}
          />
  
          <Checkbox
            id="visaapproved"
            name="visaapproved"
            value="Visa approved"
            label="Visa approved"
            checked={visaApprovedChecked}
            onChange={handleVisaApprovedChange}
            register={register1}
            disabled={true}
          />
  
          <Checkbox
            id="visarefusal"
            name="visarefusal"
            value="Visa refusal"
            label="Visa refusal"
            checked={visaRefusalChecked}
            onChange={handleVisaRefusalChange}
            register={register1}
            disabled={true}
          />
  
          <div className="text-center">
            <button type="submit" className="btn btn-primary me-2">
              Submit
            </button>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
  
  export default FilingProcessOffcanvas;
  