import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from "axios";
import icondash1 from "../Assets/Images/icondash1.png";
import Swal from "sweetalert2";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Button,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import checklogin from "./checkLogin";

const LeaderBoard = () => {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        getLeaderBoard();
    }, [startDate, endDate])

    useEffect(() => {
        // Get the current date
        const today = new Date();
        // Set the start date to the first day of the current month
        const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        // Set the end date to the last day of the current month
        const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);

        // Format dates as strings (YYYY-MM-DD)
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        // Set default values to state
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
    }, []);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getLeaderBoard = async () => {
        try {
            const item = {
                start_date: startDate,
                end_date: endDate
            }
            const response = await axios.post(`${host}/leader-board`, item);
            if (response.data.error == false) {
                const filteredData = response.data.data.map(val => {
                    const filteredLeads = val.leads.map(lead => {
                        const dates = lead.Payment_Proof_Date;
                        const amounts = lead.Amount_Paid;
                        const filteredDatesAndAmounts = dates
                            .map((date, idx) => ({ date, amount: amounts[idx] }))
                            .filter(pair => pair.date >= startDate && pair.date <= endDate);

                        return {
                            ...lead,
                            filteredDatesAndAmounts
                        };
                    });

                    const totalAmountPaid = filteredLeads.reduce((total, lead) => {
                        return total + lead.filteredDatesAndAmounts.reduce((sum, pair) => sum + Number(pair.amount), 0);
                    }, 0);

                    if (filteredLeads.length > 0) {
                        val.leads = filteredLeads
                        val.totalAmountPaid = totalAmountPaid
                        return val;
                    } else {
                        return val;
                    }
                })

                const validCounts = response.data.data.filter(val => {
                    const filteredLeads = val.leads.filter(value => {
                        const firstDate = new Date(value.Payment_Proof_Date[0]);
                        return firstDate >= new Date(startDate);
                    });
                    if (filteredLeads.length > 0) {
                        val.leadsCount = filteredLeads.length;
                        return true;
                    } else {
                        return false;
                    }
                })
                setLeaderboardData(validCounts);
            }
        } catch (error) {
            console.error("Error during API request:", error);
        }
    };

    return (
        <>
            <Sidebar />
            <CustomNavbar />
            <div className="main-content">
                <div className="container-fluid">
                    <div className="topbox">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <div className="left-logo">
                                    <div>
                                        <img src={profile2} alt="Logo" className="logo" />
                                    </div>
                                    <p className="topboxttex">Leader Board</p>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="dashboard">Dashboard</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Leads
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-12">
                        <div className="col-md-12 ">
                            <div className="d-flex justify-content-between  me-2">
                                <div className="wrap">
                                    <div>
                                        <label>Start Date:</label>
                                        <input
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className="datepick"
                                        />
                                    </div>
                                    <div>
                                        <label>End Date:</label>
                                        <input
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            className="datepick"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row tab-content">
                                <div className="card">
                                    <div className="card-header">
                                        {leaderboardData.map(val => (
                                            val.leadsCount != 0 ? (
                                                <div key={val.role._id} className="col-md-3 mb-3">
                                                    <a href={`/sales-leadboardlead?id=${val.role._id}`}>
                                                        <div className="card radius-10  border-0 border-4 cardbg-orange ">
                                                            <div className="card-body">
                                                                <div className="d-flx-dist">
                                                                    <div>
                                                                        <div className="dgl2">
                                                                            <img src={icondash1} alt="img" />
                                                                        </div>
                                                                        <p className="dist-title">Sales Executive</p>
                                                                        <p className="dist-title">Leads</p>
                                                                        <p className="dist-title">Total Received Payment</p>
                                                                    </div>
                                                                    <div>
                                                                        <h4 className="dist-title">{val.role.first_name} {val.role.last_name}</h4>
                                                                        <h4 className="dist-title">{val.leadsCount}</h4>
                                                                        <h4 className="dist-title">${val.totalAmountPaid}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ) : null
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LeaderBoard;
