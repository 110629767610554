import { BrowserRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./Components/Dashboard";
import Activity from "./Components/Activity";
import Login from "./Components/Login";
import LeadManage from "./Components/LeadManage";
import AdminLeadManage from "./Components/AdminLeadManage";
import LeadAssign from "./Components/LeadAssign";
import SaleAdminManage from "./Components/SaleAdminManage";
import FillingMAnagerAdmin from "./Components/FillingMAnagerAdmin";
import FilingManager from "./Components/FilingManager";
import FillingTeamAdmin from "./Components/FillingTeamAdmin";
import FillingTeam from "./Components/FillingTeam";
import Campaign from "./Components/Campaign";
import LeadType from "./Components/leadType";
import LeadSource from "./Components/leadSource";
import ServiceName from "./Components/ServiceName";
import LeaderBoard from "./Components/leaderBoard";
import Lead from './Components/lead';
import TermAndCondition from './Components/term$condition';
import ThankYou from './Components/thankyou';
import FreshLeads from './Components/freshLeads';
import SalesFreshLeads from './Components/sales-freshLeads';
import AdminSalesFreshLeads from './Components/adminsales-freshLeads';
import SalesPendingLeads from './Components/sales-pendingLeads';
import AdminSalesPendingLeads from './Components/adminsales-pendingLeads';
import SalesTodayLeads from "./Components/sales-todayLeads";
import AdminSalesTodayLeads from "./Components/adminsales-todayLeads";
import SalesCloseLeads from './Components/sales-closeLeads';
import AdminSalesCloseLeads from './Components/adminsales-closeLeads';
import SalesConvertLeads from './Components/sales-convertLeads';
import AdminSalesConvertLeads from './Components/adminsales-convertLeads';
import ManagerFresh from "./Components/ManagerFresh";
import ManagerStudentVisaFresh from './Components/ManagerStudent Visa - Fresh';
import ManagerStudentVisaRefusal from './Components/ManagerStudent Visa - Refusal';
import ManagerSpousalOpenWorkFresh from "./Components/ManagerSpousal Open Work Permit - Fresh";
import ManagerSpousalOpenWorkRefusal from "./Components/ManagerSpousal Open Work Permit - Refusal";
import ManagerSpousalSponsorshipFresh from "./Components/ManagerSpousal Sponsorship - Fresh";
import ManagerSpousalSponsorshipRefusal from "./Components/ManagerSpousal Sponsorship - Refusal";
import ManagerWorkPermitFresh from './Components/ManagerWork Permit - Fresh';
import ManagerWorkPermitRefusal from "./Components/ManagerWork Permit - Refusal";
import ManagerTouristVisaFresh from "./Components/ManagerTouristVisa - Fresh";
import ManagerTouristVisaRefusal from "./Components/ManagerTouristVisa - Refusal";
import ManagerRNIPFresh from "./Components/ManagerRNIP - Fresh";
import ManagerRNIPRefusal from "./Components/ManagerRNIP - Refusal";
import ManagerVisaExtensionsFresh from "./Components/ManagerVisaExtensions - Fresh";
import ManagerVisaExtensionsRefusal from "./Components/ManagerVisaExtensions - Refusal";
import ManagerFederalFilingFresh from "./Components/ManagerFederalFiling - Fresh";
import ManagerFederalFilingRefusal from "./Components/ManagerFederalFiling - Refusal";
import ManagerPNPFresh from "./Components/ManagerPNP - Fresh";
import ManagerPNPRefusal from "./Components/ManagerPNP - Refusal";
import ManagerFillingTodayFollowUps from "./Components/ManagerFilling_toady_followup";
import ManagerCaseInitiatedDateAndNotes from "./Components/ManagerCase-Initiated";
import ManagerFileInitiated from "./Components/ManagerFile-Initiated";
import ManagerDocsReceivedAndPendingDocsSent from "./Components/ManagerDocs-received";
import ManagerSopOrLettersPrepration from "./Components/ManagerSopOrlettersprepration";
import ManagerSOPOrLettersSent from "./Components/ManagerSOPorLetterssent";
import ManagerConfirmationReceived from "./Components/ManagerConfirmation-received";
import ManagerFileSent from "./Components/ManagerFilesent";
import ManagerVisaApproved from "./Components/ManagerVisaapproved";
import ManagerVisaRefusal from "./Components/ManagerVisarefusal";
import AdminManagerFresh from "./Components/AdminManagerFresh";
import AdminManagerStudentVisaFresh from "./Components/AdminManagerStudent Visa - Fresh";
import AdminManagerStudentVisaRefusal from "./Components/AdminManagerStudent Visa - Refusal";
import AdminManagerSpousalOpenWorkFresh from "./Components/AdminManagerSpousal Open Work Permit - Fresh";
import AdminManagerSpousalOpenWorkRefusal from "./Components/AdminManagerSpousal Open Work Permit - Refusal";
import AdminManagerSpousalSponsorshipFresh from "./Components/AdminManagerSpousal Sponsorship - Fresh";
import AdminManagerSpousalSponsorshipRefusal from "./Components/AdminManagerSpousal Sponsorship - Refusal";
import AdminManagerWorkPermitFresh from "./Components/AdminManagerWork Permit - Fresh";
import AdminManagerWorkPermitRefusal from "./Components/AdminManagerWork Permit - Refusal";
import AdminManagerTouristVisaFresh from "./Components/AdminManagerTouristVisa - Fresh";
import AdminManagerTouristVisaRefusal from "./Components/AdminManagerTouristVisa - Refusal";
import AdminManagerRNIPFresh from "./Components/AdminManagerRNIP - Fresh";
import AdminManagerRNIPRefusal from "./Components/AdminManagerRNIP - Refusal";
import AdminManagerVisaExtensionsFresh from "./Components/AdminManagerVisaExtensions - Fresh";
import AdminManagerVisaExtensionsRefusal from "./Components/AdminManagerVisaExtensions - Refusal";
import AdminManagerFederalFilingFresh from "./Components/AdminManagerFederalFiling - Fresh";
import AdminManagerFederalFilingRefusal from "./Components/AdminManagerFederalFiling - Refusal";
import AdminManagerPNPFresh from "./Components/AdminManagerPNP - Fresh";
import AdminManagerPNPRefusal from "./Components/AdminManagerPNP - Refusal";
import FillingTodayFollowUps from "./Components/Filling_toady_followup";
import FillingCaseInitiatedDateAndNotes from "./Components/FillingCase-Initiated";
import FillingFileInitiated from "./Components/FillingFile-Initiated";
import FillingDocsReceivedAndPendingDocsSent from "./Components/FillingDocs-received";
import FillingSopOrLettersPrepration from "./Components/FillingSopOrlettersprepration";
import FillingSOPOrLettersSent from "./Components/FillingSOPorLetterssent";
import FillingConfirmationReceived from "./Components/FillingConfirmation-received";
import FillingFileSent from "./Components/FillingFilesent";
import FillingVisaApproved from './Components/FillingVisaapproved';
import FillingVisaRefusal from "./Components/FillingVisaRefusal";
import AdminFillingTodayFollowUps from "./Components/AdminFilling_toady_followup";
import AdminFillingCaseInitiatedDateAndNotes from "./Components/AdminFillingCase-Initiated";
import AdminFillingFileInitiated from "./Components/AdminFillingFile-Initiated";
import AdminFillingDocsReceivedAndPendingDocsSent from "./Components/AdminFillingDocs-received";
import AdminFillingSopOrLettersPrepration from './Components/AdminFillingSopOrlettersprepration';
import AdminFillingSOPOrLettersSent from "./Components/AdminFillingSOPorLetterssent";
import AdminFillingConfirmationReceived from "./Components/AdminFillingConfirmation-received";
import AdminFillingFileSent from "./Components/AdminFillingFilesent";
import AdminFillingVisaApproved from "./Components/AdminFillingVisaapproved";
import AdminFillingVisaRefusal from "./Components/AdminFillingVisarefusal";
import AllLeads from "./Components/all-leads";
import ForgotPassword from "./Components/ForgotPassword";
import ResetPassword from "./Components/ResetPassword";
import OtpVerification from "./Components/OtpVerification";
import SpecificLeads from './Components/specific-leads';
import AdminRevenueConvertLeads from "./Components/adminrevenue-convertLeads";
import CampaignSpecificLeads from "./Components/campaign_specific-leads";
import SalesLeadBoardLeads from "./Components/sales-leadboardLeads";
import Analytics from "./Components/analytics";
import AnalyticsLeads from "./Components/analytics-leads";
import TodayFillingManager from "./Components/today_FilingManager";
import TodayFillingTeam from "./Components/today_FillingTeam";
import AllInfluencer from "./Components/influencer-all";
import Influencer from "./Components/influencer";
import InfluencerLeads from "./Components/influencer-leads";
import InfluencerPendingLeads from "./Components/influencer-leadspending";
import InfluencerConvertLeads from "./Components/influencer-leadsconvert";
import InfluencerWallet from "./Components/influencer-wallet";
import InfluencerUnpaidWallet from "./Components/influencer-unpaidwallet";
import InfluencerPaidWallet from "./Components/influencer-paidwallet";
import Notes from "./Components/Notes";
import Submitted from "./Components/Submitted";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/leadmanage" element={<LeadManage />} />
          <Route path="/Adminleadmanage" element={<AdminLeadManage />} />
          <Route path="/leadAssign" element={<LeadAssign />} />
          <Route path="/FillingMAnagerAdmin" element={<FillingMAnagerAdmin />} />
          <Route path="/Adminsalemanage" element={<SaleAdminManage />} />
          <Route path="/FilingManager" element={<FilingManager />} />
          <Route path="/FillingTeamAdmin" element={<FillingTeamAdmin />} />
          <Route path="/FillingTeam" element={<FillingTeam />} />
          <Route path="/campaign" element={<Campaign />} />
          <Route path="/lead-type" element={<LeadType />} />
          <Route path="/lead-source" element={<LeadSource />} />
          <Route path="/service-name" element={<ServiceName />} />
          <Route path="/leader-board" element={<LeaderBoard />} />
          <Route path="/lead" element={<Lead />} />
          <Route path="/term&condition" element={<TermAndCondition />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/fresh-leads" element={<FreshLeads />} />
          <Route path="/sales-freshleads" element={<SalesFreshLeads />} />
          <Route path="/adminsales-freshleads" element={<AdminSalesFreshLeads />} />
          <Route path="/sales-pendingleads" element={<SalesPendingLeads />} />
          <Route path="/adminsales-pendingleads" element={<AdminSalesPendingLeads />} />
          <Route path="/sales-todayleads" element={<SalesTodayLeads />} />
          <Route path="/adminsales-todayleads" element={<AdminSalesTodayLeads />} />
          <Route path="/sales-closeleads" element={<SalesCloseLeads />} />
          <Route path="/adminsales-closeleads" element={<AdminSalesCloseLeads />} />
          <Route path="/sales-convertleads" element={<SalesConvertLeads />} />
          <Route path="/adminsales-convertleads" element={<AdminSalesConvertLeads />} />
          <Route path="/manager-fresh" element={<ManagerFresh />} />
          <Route path="/managerstudentvisa-fresh" element={<ManagerStudentVisaFresh />} />
          <Route path="/managerstudentvisa-refusal" element={<ManagerStudentVisaRefusal />} />
          <Route path="/managerspousal-openworkfresh" element={<ManagerSpousalOpenWorkFresh />} />
          <Route path="/managerspousal-openworkRefusal" element={<ManagerSpousalOpenWorkRefusal />} />
          <Route path="/managerspousal-sponsorshipfresh" element={<ManagerSpousalSponsorshipFresh />} />
          <Route path="/managerspousal-sponsorshiprefusal" element={<ManagerSpousalSponsorshipRefusal />} />
          <Route path="/managerworkpermit-fresh" element={<ManagerWorkPermitFresh />} />
          <Route path="/managerworkpermit-Refusal" element={<ManagerWorkPermitRefusal />} />
          <Route path="/managertouristvisa-fresh" element={<ManagerTouristVisaFresh />} />
          <Route path="/managertouristvisa-Refusal" element={<ManagerTouristVisaRefusal />} />
          <Route path="/managerrnip-fresh" element={<ManagerRNIPFresh />} />
          <Route path="/managerrnip-refusal" element={<ManagerRNIPRefusal />} />
          <Route path="/managervisaextensions-fresh" element={<ManagerVisaExtensionsFresh />} />
          <Route path="/managervisaextensions-refusal" element={<ManagerVisaExtensionsRefusal />} />
          <Route path="/managerfederalfiling-fresh" element={<ManagerFederalFilingFresh />} />
          <Route path="/managerfederalfiling-Refusal" element={<ManagerFederalFilingRefusal />} />
          <Route path="/managerpnp-fresh" element={<ManagerPNPFresh />} />
          <Route path="/managerpnp-refusal" element={<ManagerPNPRefusal />} />
          <Route path="/managerfillingtoday-followup" element={<ManagerFillingTodayFollowUps />} />
          <Route path="/managercase-initiated" element={<ManagerCaseInitiatedDateAndNotes />} />
          <Route path="/managerfile-initiated" element={<ManagerFileInitiated />} />
          <Route path="/managerdocs-received" element={<ManagerDocsReceivedAndPendingDocsSent />} />
          <Route path="/managersop-letterprepration" element={<ManagerSopOrLettersPrepration />} />
          <Route path="/managersop-lettersent" element={<ManagerSOPOrLettersSent />} />
          <Route path="/managerconfirmation-received" element={<ManagerConfirmationReceived />} />
          <Route path="/managerfile-sent" element={<ManagerFileSent />} />
          <Route path="/managervisa-approved" element={<ManagerVisaApproved />} />
          <Route path="/managervisa-refusal" element={<ManagerVisaRefusal />} />
          <Route path="/adminmanager-fresh" element={<AdminManagerFresh />} />
          <Route path="/adminmanagerstudentvisa-fresh" element={<AdminManagerStudentVisaFresh />} />
          <Route path="/adminmanagerstudentvisa-refusal" element={<AdminManagerStudentVisaRefusal />} />
          <Route path="/adminmanagerspousal-openworkfresh" element={<AdminManagerSpousalOpenWorkFresh />} />
          <Route path="/adminmanagerspousal-openworkRefusal" element={<AdminManagerSpousalOpenWorkRefusal />} />
          <Route path="/adminmanagerspousal-sponsorshipfresh" element={<AdminManagerSpousalSponsorshipFresh />} />
          <Route path="/adminmanagerspousal-sponsorshiprefusal" element={<AdminManagerSpousalSponsorshipRefusal />} />
          <Route path="/adminmanagerworkpermit-fresh" element={<AdminManagerWorkPermitFresh />} />
          <Route path="/adminmanagerworkpermit-Refusal" element={<AdminManagerWorkPermitRefusal />} />
          <Route path="/adminmanagertouristvisa-fresh" element={<AdminManagerTouristVisaFresh />} />
          <Route path="/adminmanagertouristvisa-Refusal" element={<AdminManagerTouristVisaRefusal />} />
          <Route path="/adminmanagerrnip-fresh" element={<AdminManagerRNIPFresh />} />
          <Route path="/adminmanagerrnip-refusal" element={<AdminManagerRNIPRefusal />} />
          <Route path="/adminmanagervisaextensions-fresh" element={<AdminManagerVisaExtensionsFresh />} />
          <Route path="/adminmanagervisaextensions-refusal" element={<AdminManagerVisaExtensionsRefusal />} />
          <Route path="/adminmanagerfederalfiling-fresh" element={<AdminManagerFederalFilingFresh />} />
          <Route path="/adminmanagerfederalfiling-Refusal" element={<AdminManagerFederalFilingRefusal />} />
          <Route path="/adminmanagerpnp-fresh" element={<AdminManagerPNPFresh />} />
          <Route path="/adminmanagerpnp-refusal" element={<AdminManagerPNPRefusal />} />
          <Route path="/fillingtoday-followup" element={<FillingTodayFollowUps />} />
          <Route path="/fillingcase-initiated" element={<FillingCaseInitiatedDateAndNotes />} />
          <Route path="/fillingfile-initiated" element={<FillingFileInitiated />} />
          <Route path="/fillingdocs-received" element={<FillingDocsReceivedAndPendingDocsSent />} />
          <Route path="/fillingsop-letterprepration" element={<FillingSopOrLettersPrepration />} />
          <Route path="/fillingsop-lettersent" element={<FillingSOPOrLettersSent />} />
          <Route path="/fillingconfirmation-received" element={<FillingConfirmationReceived />} />
          <Route path="/fillingfile-sent" element={<FillingFileSent />} />
          <Route path="/fillingvisa-approved" element={<FillingVisaApproved />} />
          <Route path="/fillingvisa-refusal" element={<FillingVisaRefusal />} />
          <Route path="/adminfillingtoday-followup" element={<AdminFillingTodayFollowUps />} />
          <Route path="/adminfillingcase-initiated" element={<AdminFillingCaseInitiatedDateAndNotes />} />
          <Route path="/adminfillingfile-initiated" element={<AdminFillingFileInitiated />} />
          <Route path="/adminfillingdocs-received" element={<AdminFillingDocsReceivedAndPendingDocsSent />} />
          <Route path="/adminfillingsop-letterprepration" element={<AdminFillingSopOrLettersPrepration />} />
          <Route path="/adminfillingsop-lettersent" element={<AdminFillingSOPOrLettersSent />} />
          <Route path="/adminfillingconfirmation-received" element={<AdminFillingConfirmationReceived />} />
          <Route path="/adminfillingfile-sent" element={<AdminFillingFileSent />} />
          <Route path="/submitted" element={<Submitted />} />
          <Route path="/adminfillingvisa-approved" element={<AdminFillingVisaApproved />} />
          <Route path="/adminfillingvisa-refusal" element={<AdminFillingVisaRefusal />} />
          <Route path="/all-leads" element={<AllLeads />} />
          <Route path="/specific-leads" element={<SpecificLeads />} />
          <Route path="/adminrevenue-convertleads" element={<AdminRevenueConvertLeads />} />
          <Route path="/campaignspecific-leads" element={<CampaignSpecificLeads />} />
          <Route path="/sales-leadboardlead" element={<SalesLeadBoardLeads />} />
          <Route path="/analytics-board" element={<Analytics />} />
          <Route path="/analytics-list" element={<AnalyticsLeads />} />
          <Route path="/today-fillingmanager" element={<TodayFillingManager />} />
          <Route path="/today-fillingteam" element={<TodayFillingTeam />} />
          <Route path="/ccs-partner-li" element={<AllInfluencer />} />
          <Route path="/ccs-partner" element={<Influencer />} />
          <Route path="/influencer-leads" element={<InfluencerLeads />} />
          <Route path="/influencer-pendingleads" element={<InfluencerPendingLeads />} />
          <Route path="/influencer-convertleads" element={<InfluencerConvertLeads />} />
          <Route path="/influencer-wallet" element={<InfluencerWallet />} />
          <Route path="/influencer-unpaidwallet" element={<InfluencerUnpaidWallet />} />
          <Route path="/influencer-paidwallet" element={<InfluencerPaidWallet />} />
          <Route path="/notes" element={<Notes />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
