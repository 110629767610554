import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import checklogin from "./checkLogin";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import infomationLoginUser from "./LoginUSerInfo";
import axios from "axios";
import Offcanvas from "react-bootstrap/Offcanvas";

export default function AdminFillingVisaRefusal({ paginationTotalRows, onPageChange, perPage, onPerPageChange, paginationRowsPerPageOptions, paginationPerPageLabel }) {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [apiResponse, setApiResponse] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [followup, setFollowup] = useState(false);
    const [caseInitiatedChecked, setCaseInitiatedChecked] = useState(false);
    const [fileInitiatedChecked, setFileIntiatedChecked] = useState(false);
    const [docReceivedChecked, setDocsReceivedChecked] = useState(false);
    const [soppreprationChecked, setSoppreprationChecked] = useState(false);
    const [sopLetterChecked, setSopLettersChecked] = useState(false);
    const [confirmReceivedChecked, setConfirmRecievedChecked] = useState(false);
    const [fileSentChecked, setFilesentChecked] = useState(false);
    const [filesSubmittedChecked, setFilesSubmittedChecked] = useState(null);
    const [visaApprovedChecked, setVisaapprovedChecked] = useState(false);
    const [visaRefusalChecked, setVisaRefusalChecked] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(perPage || 10);
    const [serviceName, setServiceName] = useState([]);

    useEffect(() => {
        checklogin();
        infomationLoginUser();
        fetchData();
        fetchServiceName();
    }, []);

    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage, searchText, startDate, endDate, totalRows]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${host}/getlead-fillingcase`, {
                params: {
                    page: currentPage,
                    perPage: itemsPerPage,
                    searchQuery: searchText,
                    startDate: startDate,
                    endDate: endDate,
                    id: '',
                    status: "Visa refusal"
                }
            });
            if (response.data.error == false) {
                setTotalRows(response.data.totalRows);
                setData(response.data.data);
                setFilteredData(filterData(response.data.data));
            } else {
                console.error("Failed to fetch data");
            }
        } catch (error) {
            console.error("Error during API request:", error);
        }
    };

    const fetchServiceName = async () => {
        try {
            const response = await axios.get(`${host}/listservice-name`);
            if (response.data.error == false) {
                setServiceName(response.data);
            }
        } catch (error) {
            console.error("Error during API request:", error);
        }
    };

    let paginatedData = [];
    if (Array.isArray(data)) {
        const startIndex = (currentPage - 1) * perPage;
        const endIndex = startIndex + perPage;
        paginatedData = data.slice(startIndex, endIndex);
    } else {
        console.error("Data is not an array.");
    }

    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => index + 1 + (currentPage - 1) * itemsPerPage,
            sortable: true,
            width: '100px !important',
        },
        {
            name: "Full Name",
            selector: (row) => (
                <div onClick={() => userFollowupShow(row._id)} style={{ cursor: "pointer" }}>
                    <span className="text-info">{`${row.first_name} ${row.last_name || ""}`}</span>
                </div>
            ),
            sortable: true,
            width: "200px !important"
        },
        {
            name: "Email",
            selector: (row) => row.email,

            sortable: true,
            width: "250px !important"
        },
        {
            name: "Contact no.",
            selector: (row) => row.contact_number,

            sortable: true,
        },
        {
            name: "Country.",
            selector: (row) => row.country_name,

            sortable: true,
        },
        {
            name: "Sales Executive.",
            selector: (row) => row.Lead_AssignName,
            sortable: true,
        },
        {
            name: "Lead Type.",
            selector: (row) => row.Lead_Type,

            sortable: true,
        },
        {
            name: "Gender",
            selector: (row) => row.gender,
            sortable: true,
            width: '100px !important',
        },
        {
            name: "Alternate Contact No.",
            selector: (row) => row.alt_contact_number,

            sortable: true,
            width: "200px !important"
        },
        {
            name: "Age.",
            selector: (row) => row.age == "undefined" ? "N/A" : row.age,

            sortable: true,
            width: '100px !important',
        },
        {
            name: "State.",
            selector: (row) => row.state_name,

            sortable: true,
        },
        {
            name: "Education.",
            selector: (row) => row.education,

            sortable: true,
        },
        {
            name: "Lead Source.",
            selector: (row) => row.Lead_Source,

            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
              <button
                className="btn btn-danger"
                onClick={() => handleRestart(row._id)}
              >
                Restart
              </button>
            ),
          },
    ];



    const handleRestart = async (id) => {   
        try {
            const response = await axios.put(`${host}/visarefusal-update-status`, {
                id: id,
            });
            if (response.data.error) {
                window.location.reload();
            } else {
                window.location.reload();
            }
    
        } catch (error) {
            console.error('Error while updating the status:', error);
            alert(`Error while updating status: ${error.response ? error.response.data.message : error.message}`);
        }
    };
    
    
    

    const userFollowupShow = async (id) => {
        try {
          const response = await axios.post(`${host}/GetByIdLead`, { id: id });
          setApiResponse(response.data);
      
          if (response.data.data.Filing_Process.length > 0) {
            for (let i = response.data.data.Filing_Process.length - 1; i >= 0; i--) {
              const val = response.data.data.Filing_Process[i];
      
              // Check if caseInitiated is true and set the corresponding checkbox
              if (val.caseInitiated === 'Case Initiated') {
                setCaseInitiatedChecked(true);
              }
      
              // Check if the filing process has moved to fileIntiated
              if (val.caseInitiated === "true" && val.fileIntiated === 'File Initiated or docs checklist sent') {
                setFileIntiatedChecked(true);
              }
      
              // Check if docsReceived is true and set the corresponding checkbox
              if (val.caseInitiated === 'true' && val.fileIntiated === 'true' && val.docsReceived === 'Docs received & Pending docs sent') {
                setDocsReceivedChecked(true);
              }
      
              // Check if sopprepration is true and set the corresponding checkbox
              if (val.caseInitiated === "true" && val.fileIntiated === "true" && val.docsReceived === "true" && val.sopprepration === 'Sop Or letters prepration & Forms prep') {
                setSoppreprationChecked(true);
              }
      
              // Check if sopletters is true and set the corresponding checkbox
              if (val.caseInitiated === 'true' && val.fileIntiated === 'true' && val.docsReceived === 'true' && val.sopprepration === 'true' && val.sopletters === 'SOP or Letters sent to client') {
                setSopLettersChecked(true);
              }
      
              // Check if confirmrecieved is true and set the corresponding checkbox
              if (val.caseInitiated === 'true' && val.fileIntiated === 'true' && val.docsReceived === 'true' && val.sopprepration === 'true' && val.sopletters === 'true' && val.confirmrecieved === 'Confirmation received on SOP, letters and docs, Forms confirmation') {
                setConfirmRecievedChecked(true);
              }
      
              // Check if filesent is true and set the corresponding checkbox
              if (val.caseInitiated === 'true' && val.fileIntiated === 'true' && val.docsReceived === 'true' && val.sopprepration === 'true' && val.sopletters === 'true' && val.confirmrecieved === 'true' && val.filesent === 'File sent for Upload or submission') {
                setFilesentChecked(true);
              }
      
              // Check if filessubmitted is true and set the corresponding checkbox
              if (val.caseInitiated === 'true' && val.fileIntiated === 'true' && val.docsReceived === 'true' && val.sopprepration === 'true' && val.sopletters === 'true' && val.confirmrecieved === 'true' && val.filesent === 'true' && val.filessubmitted === 'Files Submitted') {
                setFilesSubmittedChecked(true);
              }
      
              // Handle Visa Approved or Visa Refusal
              if (val.caseInitiated === 'true' && val.fileIntiated === 'true' && val.docsReceived === 'true' && val.sopprepration === 'true' && val.sopletters === 'true' && val.confirmrecieved === 'true' && val.filesent === 'true' && val.filessubmitted === 'true' && (val.visaapproved === 'Visa approved' || val.visarefusal === 'Visa refusal')) {
                if (val.visaapproved === 'Visa approved') {
                  setVisaapprovedChecked(true);
                  setVisaRefusalChecked(false); // Deselect Visa Refusal when Visa Approved is selected
                } else if (val.visarefusal === 'Visa refusal') {
                  setVisaapprovedChecked(false); // Deselect Visa Approved when Visa Refusal is selected
                  setVisaRefusalChecked(true);
                }
              }
      
            }
          } else {
            console.log("No filing process data found.");
          }
        } catch (error) {
          console.error("Error fetching follow-up data:", error);
        }
        setFollowup(true);
      };
      

    const followupClose = () => {
        setFollowup(false);
        setCaseInitiatedChecked(false);
        setFileIntiatedChecked(false);
        setDocsReceivedChecked(false);
        setSoppreprationChecked(false);
        setSopLettersChecked(false);
        setConfirmRecievedChecked(false);
        setFilesentChecked(false);
        setVisaapprovedChecked(false);
        setVisaRefusalChecked(false);
    };

    if (apiResponse && apiResponse.data) {
        var followUpStatus = apiResponse.data
        const followUpDates = apiResponse.data.Followup_Date_fiiling_team;
        var followupdatepayment = apiResponse.data.Followup_Date

        const NextFollowupDate = apiResponse.data.Next_Followup_Date_fiiling_team;
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };

        // Format regular follow-up dates
        var formattedFollowUpDates = followUpDates.map(dateString => {
            if (dateString) {
                const followUpDate = new Date(dateString);
                return followUpDate.toLocaleDateString('en-US', options);
            } else {
                return null;
            }
        });
        var followupdatepaymentData = followupdatepayment.map(dateStringpayment => {
            if (dateStringpayment) {
                const followUpDatepayment = new Date(dateStringpayment);
                return followUpDatepayment.toLocaleDateString('en-US', options);
            } else {
                return null;
            }
        });
        // Format Next Follow-up Date
        var formattedNextFollowupDate = NextFollowupDate.map(dateStringnext => {
            if (dateStringnext) {
                const followUpDateNext = new Date(dateStringnext);
                return followUpDateNext.toLocaleDateString('en-US', options);
            } else {
                return null;
            }
        });
        var notes = apiResponse.data.Notes_fiiling_team
        var TotalAmount = apiResponse.data.Total_Amount
    }

    const handleItemsPerPageChange = (event) => {
        const newItemsPerPage = parseInt(event.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); // Reset current page when changing items per page
        // Call function to fetch data with the new items per page setting
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const formatLeadDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());

        return `${day}-${month}-${year}`;
    };

    const formatLeadTime = (timeString) => {
        const time = new Date(timeString);
        const hours = String(time.getHours()).padStart(2, '0');
        const minutes = String(time.getMinutes()).padStart(2, '0');
        const seconds = String(time.getSeconds()).padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const filterData =
        data && data.data
            ? data.data.filter((row) => {
                return (
                    Object.values(row).some(
                        (value) =>
                            typeof value === 'string' &&
                            value.toLowerCase().includes(searchText.toLowerCase())
                    )
                );
                return false;
            })
            : [];


    return (
        <>
            <Sidebar />
            <CustomNavbar />
            <div className="main-content">
                <div className="container-fluid">
                    <div className="topbox">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <div className="left-logo">
                                    <div>
                                        <img src={profile2} alt="Logo" className="logo" />
                                    </div>
                                    <p className="topboxttex">Lead Management</p>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="dashboard">Dashboard</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Leads
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-3 my-3">
                        <div className="col-md-12">
                            <div className="wrap mt-0" >
                                <div style={{ display: "flex" }}>
                                    <div className="search">
                                        <input
                                            type="text"
                                            value={searchText}
                                            onChange={handleSearch}
                                            placeholder="Search"
                                            style={{ borderRadius: "7px", height: "35px", marginTop: "20px", borderColor: "#180A4C" }}
                                        />
                                    </div>
                                    <div>
                                        <label>Start Date:</label>
                                        <input
                                            type="date"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className="datepick"
                                        />
                                    </div>
                                    <div>
                                        <label>End Date:</label>
                                        <input
                                            type="date"
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            className="datepick"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="row tab-content">
                                <div
                                    id="list-view"
                                    className="tab-pane fade active show col-lg-12"
                                >
                                    <div className="card">
                                        <div className="card-header">
                                            <DataTable
                                                title="Visa refusal"
                                                className="custom-data-table"
                                                columns={columns}
                                                data={data}
                                                // pagination
                                                // paginationTotalRows={data.length}
                                                // onChangePage={handlePageChange}
                                                // paginationPerPage={perPage}
                                                // paginationRowsPerPageOptions={[10, 20, 30]} // Customize rows per page options
                                                // onChangeRowsPerPage={handlePerPageChange}
                                                paginationPerPageLabel="Rows per page"
                                            />

                                            {/* Pagination */}
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <span>Rows per page:</span>
                                                <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={30}>30</option>
                                                </select>
                                                <span>{`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage)} of ${totalRows}`}</span>
                                                <div>
                                                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                                        Previous Page
                                                    </button>
                                                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={((currentPage - 1) * itemsPerPage + 1) >= totalRows}>
                                                        Next Page
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Offcanvas
                    className="profileCanvas"
                    show={followup}
                    onHide={followupClose}
                    placement={"end"}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            {apiResponse && apiResponse.data && (
                                <div className="profilFlex">
                                    <div className="prbg">
                                        <i className="fa-solid fa-user" />
                                    </div>
                                    <h6 className="prftitle">{apiResponse.data.first_name}  {apiResponse.data.last_name} </h6>
                                </div>
                            )}
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form action="">
                            {apiResponse && apiResponse.data && (
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Name</h6>
                                                <p>{apiResponse.data.first_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Gender</h6>
                                                <p>{apiResponse.data.gender}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Email</h6>
                                                <p>{apiResponse.data.email}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Age</h6>
                                                <p>{apiResponse.data.age == "undefined" ? "" : apiResponse.data.age}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Contact No.</h6>
                                                <p>{apiResponse.data.contact_number}</p>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Alt. Contact No. </h6>
                                                <p>{apiResponse.data.alt_contact_number}</p>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Country</h6>
                                                <p>{apiResponse.data.country_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>State</h6>
                                                <p>{apiResponse.data.state_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Education</h6>
                                                <p>{apiResponse.data.education}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Note</h6>
                                                <p>{apiResponse.data.note}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Services</h6>
                                                <p>{apiResponse.data.Services}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="namedetail">
                                                <h6>Upload File</h6>
                                                {apiResponse.data.Upload_File == null ? "" : <img src={`https://api.checkcheckservices.in/public/${apiResponse.data.Upload_File}`} style={{ width: "150px" }} />}
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="rowv mt-2">
                                        <div className="col-md-12">
                                            <div className="mytabpill">
                                                <ul
                                                    className="nav nav-pills nav-justified my-2"
                                                    id="myTabs"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            id="commentary-tab"
                                                            data-bs-toggle="pill"
                                                            href="#Commentary"
                                                            role="tab"
                                                        >
                                                            Details
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            id="videos-tab"
                                                            data-bs-toggle="pill"
                                                            href="#Videos"
                                                            role="tab"
                                                        >
                                                            Follow-ups
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            id="events-tab"
                                                            data-bs-toggle="pill"
                                                            href="#Events"
                                                            role="tab"
                                                        >
                                                            Payment
                                                        </a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            id="filing-tab"
                                                            data-bs-toggle="pill"
                                                            href="#Filing"
                                                            role="tab"
                                                        >
                                                            Filing Process
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane fade show active"
                                                        id="Commentary"
                                                        role="tabpanel"
                                                    >
                                                        <div className="row">
                                                            <div className="col-md-12 my-3">
                                                                <div className="accrordiondiv">
                                                                    <div
                                                                        className="accordion"
                                                                        id="accordionExample"
                                                                    >
                                                                        <div className="accordion-item">
                                                                            <h2
                                                                                className="accordion-header"
                                                                                id="headingOne"
                                                                            >
                                                                                <button
                                                                                    className="accordion-button collapsed"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseOne"
                                                                                    aria-expanded="true"
                                                                                    aria-controls="collapseOne"
                                                                                >
                                                                                    Lead Information
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="collapseOne"
                                                                                className="accordion-collapse collapse"
                                                                                aria-labelledby="headingOne"
                                                                                data-bs-parent="#accordionExample"
                                                                            >
                                                                                <div className="accordion-body">
                                                                                    <table className="table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <th>Lead Status</th>
                                                                                                <td>{apiResponse.data.status_convert[apiResponse.data.status_convert.length - 1]}</td>
                                                                                            </tr>

                                                                                            <tr>
                                                                                                <th>Lead Type</th>
                                                                                                <td>{apiResponse.data.Lead_Type}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Lead Source</th>
                                                                                                <td>{apiResponse.data.Lead_Source}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Lead Assign Sales</th>
                                                                                                <td>{apiResponse.data.Lead_AssignName == null ? "N/A" : apiResponse.data.Lead_AssignName}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Lead Assign Filling Manager</th>
                                                                                                <td>{apiResponse.data.Filing_ManagerName == null ? "N/A" : apiResponse.data.Filing_ManagerName}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th>Lead Assign Filling Team</th>
                                                                                                <td>{apiResponse.data.Filing_TeamName == null ? "N/A" : apiResponse.data.Filing_TeamName}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tab-pane fade"
                                                        id="Videos"
                                                        role="tabpanel"
                                                    >
                                                        <div className="row">
                                                            <div className="col-md-12 mb-3">
                                                                <div className="table-responsive tablebg">
                                                                    <table className="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Followup Status</th>
                                                                                <th>Follow up Date</th>
                                                                                <th>Note</th>
                                                                                <th>Next Follow up Date</th>
                                                                            </tr>
                                                                            {followUpStatus.Followup_Date_fiiling_team.slice().reverse().map((date, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{followUpStatus.Filing_Team_Status[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
                                                                                    <td>{formatLeadDate(date)} {formatLeadTime(date)}</td>
                                                                                    <td>{followUpStatus.Notes_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
                                                                                    <td>{followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]) : ""}</td>
                                                                                </tr>
                                                                            ))}
                                                                            {followUpStatus.Followup_Date.slice().reverse().map((formattedDate, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{followUpStatus.status_convert[followUpStatus.Followup_Date.length - 1 - index]}</td>
                                                                                    <td>{formatLeadDate(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])} {formatLeadTime(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])}</td>
                                                                                    <td>{followUpStatus.Notes_sales[followUpStatus.Followup_Date.length - 1 - index]}</td>
                                                                                    <td>{followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index]) : ""}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tab-pane fade"
                                                        id="Events"
                                                        role="tabpanel"
                                                    >
                                                        <div className="row justify-content-center">
                                                            <div className="col-md-3">
                                                                <div className="namedetail">
                                                                    <h6>Total Payment</h6>
                                                                    {apiResponse.data.status_convert.includes('Convert') ? (
                                                                        <p>{followUpStatus.Total_Amount.slice().reverse().map((formattedDate, index) => (
                                                                            <tr key={index}>
                                                                                <td>${formattedDate}</td>
                                                                            </tr>
                                                                        ))}</p>
                                                                    ) : (
                                                                        <p>N/A</p>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="namedetail">
                                                                    <h6>Payment Received</h6>
                                                                    {apiResponse.data.status_convert.includes('Convert') ? (
                                                                        <p>{followUpStatus.Amount_Paid.slice().reverse().map((formattedDate, index) => (
                                                                            <tr key={index}>
                                                                                <td>${formattedDate}</td>
                                                                            </tr>
                                                                        ))}</p>
                                                                    ) : (
                                                                        <p>N/A</p>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="namedetail">
                                                                    <h6>Remaining Payment</h6>
                                                                    {apiResponse.data.status_convert.includes('Convert') ? (
                                                                        <p>{followUpStatus.Amount_Due.slice().reverse().map((formattedDate, index) => (
                                                                            <tr key={index}>
                                                                                <td>${formattedDate}</td>
                                                                            </tr>
                                                                        ))}</p>
                                                                    ) : (
                                                                        <p>N/A</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12 mb-3">
                                                                <div className="table-responsive tablebg">
                                                                    <table className="table">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Date</th>
                                                                                <th>Amount</th>
                                                                                <th>Proof</th>
                                                                            </tr>

                                                                            {apiResponse.data.status_convert.includes('Convert') ? (
                                                                                followUpStatus.Payment_Proof_Date.slice().reverse().map((formattedDate, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{formatLeadDate(formattedDate)}</td>
                                                                                        <td>{followUpStatus.Amount_Paid[followUpStatus.Payment_Proof_Date.length - 1 - index]}</td>
                                                                                        <td>
                                                                                            <a href={`https://api.checkcheckservices.in/public/${followUpStatus.Upload_Payment_Proof[followUpStatus.Payment_Proof_Date.length - 1 - index]}`} download={`PaymentProof-${index}`} target="_blank" >
                                                                                                View
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            ) : (
                                                                                <p></p>
                                                                            )}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="tab-pane fade"
                                                        id="Filing"
                                                        role="tabpanel"
                                                    >
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-md-12 my-3">
                                                                    <div className="accrordiondiv">
                                                                        <div
                                                                            className="accordion"
                                                                            id="accordionExample2"
                                                                        >
                                                                            <div className="accordion-item">
                                                                                <h2
                                                                                    className="accordion-header"
                                                                                    id="headingOne"
                                                                                >
                                                                                    <button
                                                                                        className="accordion-button collapsed"
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseOne"
                                                                                        aria-expanded="true"
                                                                                        aria-controls="collapseOne"
                                                                                    >
                                                                                        Services
                                                                                    </button>
                                                                                </h2>
                                                                                <div
                                                                                    id="collapseOne"
                                                                                    className="accordion-collapse collapse"
                                                                                    aria-labelledby="headingOne"
                                                                                    data-bs-parent="#accordionExample2"
                                                                                >
                                                                                    <div className="accordion-body">
                                                                                        {serviceName.data && serviceName.data.length > 0 ? (
                                                                                            serviceName.data.map((val, key) => (
                                                                                                <div className="form-check">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="radio"
                                                                                                        name="flexRadioDefault"
                                                                                                        id="flexRadioDefault1"
                                                                                                        checked={apiResponse.data.Services === val.service_name}

                                                                                                    />
                                                                                                    <label
                                                                                                        className="form-check-label forcheckfont"
                                                                                                        htmlfor="flexRadioDefault1"
                                                                                                    >
                                                                                                        {val.service_name}
                                                                                                    </label>
                                                                                                </div>
                                                                                            ))
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="accordion-item">
                                                                                <h2
                                                                                    className="accordion-header"
                                                                                    id="headingTwo"
                                                                                >
                                                                                    <button
                                                                                        class="accordion-button collapsed"
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target="#collapseTwo"
                                                                                        aria-expanded="false"
                                                                                        aria-controls="collapseTwo"
                                                                                    >
                                                                                        Filing Status
                                                                                    </button>
                                                                                </h2>
                                                                                <div
                                                                                    id="collapseTwo"
                                                                                    className="accordion-collapse collapse"
                                                                                    aria-labelledby="headingTwo"
                                                                                    data-bs-parent="#accordionExample2"
                                                                                >
                                                                                    <div className="accordion-body accrfp">
                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="caseInitiated"
                                                                                                checked={caseInitiatedChecked}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>Case Initiated</h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="fileIntiated"
                                                                                                checked={fileInitiatedChecked}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>
                                                                                                    File Initiated or docs checklist
                                                                                                    sent
                                                                                                </h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="docsReceived"
                                                                                                checked={docReceivedChecked}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>
                                                                                                    Docs received & Pending docs
                                                                                                    sent{" "}
                                                                                                </h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="sopprepration"
                                                                                                checked={soppreprationChecked}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>
                                                                                                    Sop Or letters prepration &
                                                                                                    Forms prep
                                                                                                </h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="sopletters"
                                                                                                checked={sopLetterChecked}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>
                                                                                                    SOP or Letters sent to client{" "}
                                                                                                </h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="confirmrecieved"
                                                                                                checked={confirmReceivedChecked}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>
                                                                                                    Confirmation received on SOP,
                                                                                                    letters and docs, Forms
                                                                                                    confirmation{" "}
                                                                                                </h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="filesent"
                                                                                                checked={fileSentChecked}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>
                                                                                                    File sent for Upload or
                                                                                                    submission
                                                                                                </h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="filesent"
                                                                                                checked={filesSubmittedChecked ?? true}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>
                                                                                                Files Submitted
                                                                                                </h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="visaapproved"
                                                                                                checked={visaApprovedChecked}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>Visa approved</h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="form-check frmch">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="checkbox"
                                                                                                defaultValue
                                                                                                id="flexCheckDefault"
                                                                                                name="visarefusal"
                                                                                                checked={visaRefusalChecked ?? true}
                                                                                                disabled
                                                                                            />
                                                                                            <div className="caseinitiate">
                                                                                                <h6>Visa refusal</h6>
                                                                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>

        </>
    );
}














































// import React, { useState, useEffect } from "react";
// import DataTable from "react-data-table-component";
// import checklogin from "./checkLogin";
// import CustomNavbar from "../directives/navbar";
// import Sidebar from "../directives/barside";
// import host from "./utils";
// import profile2 from "../Assets/Images/topboxlogo.png";
// import infomationLoginUser from "./LoginUSerInfo";
// import axios from "axios";
// import Offcanvas from "react-bootstrap/Offcanvas";

// export default function AdminFillingVisaRefusal({ paginationTotalRows, onPageChange, perPage, onPerPageChange, paginationRowsPerPageOptions, paginationPerPageLabel }) {
//     const [data, setData] = useState([]);
//     const [searchText, setSearchText] = useState("");
//     const [startDate, setStartDate] = useState("");
//     const [endDate, setEndDate] = useState("");
//     const [apiResponse, setApiResponse] = useState([]);
//     const [filteredData, setFilteredData] = useState([]);
//     const [totalRows, setTotalRows] = useState(0);
//     const [followup, setFollowup] = useState(false);
//     const [caseInitiatedChecked, setCaseInitiatedChecked] = useState(false);
//     const [fileInitiatedChecked, setFileIntiatedChecked] = useState(false);
//     const [docReceivedChecked, setDocsReceivedChecked] = useState(false);
//     const [soppreprationChecked, setSoppreprationChecked] = useState(false);
//     const [sopLetterChecked, setSopLettersChecked] = useState(false);
//     const [confirmReceivedChecked, setConfirmRecievedChecked] = useState(false);
//     const [fileSentChecked, setFilesentChecked] = useState(false);
//     const [visaApprovedChecked, setVisaapprovedChecked] = useState(false);
//     const [visaRefusalChecked, setVisaRefusalChecked] = useState(false);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(perPage || 10);
//     const [serviceName, setServiceName] = useState([]);

//     useEffect(() => {
//         checklogin();
//         infomationLoginUser();
//         fetchData();
//         fetchServiceName();
//     }, []);

//     useEffect(() => {
//         fetchData();
//     }, [currentPage, itemsPerPage, searchText, startDate, endDate, totalRows]);

//     const fetchData = async () => {
//         try {
//             const response = await axios.get(`${host}/getlead-fillingcase`, {
//                 params: {
//                     page: currentPage,
//                     perPage: itemsPerPage,
//                     searchQuery: searchText,
//                     startDate: startDate,
//                     endDate: endDate,
//                     id: '',
//                     status: "Visa refusal"
//                 }
//             });
//             if (response.data.error == false) {
//                 setTotalRows(response.data.totalRows);
//                 setData(response.data.data);
//                 setFilteredData(filterData(response.data.data));
//             } else {
//                 console.error("Failed to fetch data");
//             }
//         } catch (error) {
//             console.error("Error during API request:", error);
//         }
//     };

//     const fetchServiceName = async () => {
//         try {
//             const response = await axios.get(`${host}/listservice-name`);
//             if (response.data.error == false) {
//                 setServiceName(response.data);
//             }
//         } catch (error) {
//             console.error("Error during API request:", error);
//         }
//     };

//     let paginatedData = [];
//     if (Array.isArray(data)) {
//         const startIndex = (currentPage - 1) * perPage;
//         const endIndex = startIndex + perPage;
//         paginatedData = data.slice(startIndex, endIndex);
//     } else {
//         console.error("Data is not an array.");
//     }

//     const columns = [
//         {
//             name: "Sr. No.",
//             selector: (row, index) => index + 1 + (currentPage - 1) * itemsPerPage,
//             sortable: true,
//             width: '100px !important',
//         },
//         {
//             name: "Full Name",
//             selector: (row) => (
//                 <div onClick={() => userFollowupShow(row._id)} style={{ cursor: "pointer" }}>
//                     <span className="text-info">{`${row.first_name} ${row.last_name || ""}`}</span>
//                 </div>
//             ),
//             sortable: true,
//             width: "200px !important"
//         },
//         {
//             name: "Email",
//             selector: (row) => row.email,

//             sortable: true,
//             width: "250px !important"
//         },
//         {
//             name: "Contact no.",
//             selector: (row) => row.contact_number,

//             sortable: true,
//         },
//         {
//             name: "Country.",
//             selector: (row) => row.country_name,

//             sortable: true,
//         },
//         {
//             name: "Sales Executive.",
//             selector: (row) => row.Lead_AssignName,
//             sortable: true,
//         },
//         {
//             name: "Lead Type.",
//             selector: (row) => row.Lead_Type,

//             sortable: true,
//         },
//         {
//             name: "Gender",
//             selector: (row) => row.gender,
//             sortable: true,
//             width: '100px !important',
//         },
//         {
//             name: "Alternate Contact No.",
//             selector: (row) => row.alt_contact_number,

//             sortable: true,
//             width: "200px !important"
//         },
//         {
//             name: "Age.",
//             selector: (row) => row.age == "undefined" ? "N/A" : row.age,

//             sortable: true,
//             width: '100px !important',
//         },
//         {
//             name: "State.",
//             selector: (row) => row.state_name,

//             sortable: true,
//         },
//         {
//             name: "Education.",
//             selector: (row) => row.education,

//             sortable: true,
//         },
//         {
//             name: "Lead Source.",
//             selector: (row) => row.Lead_Source,

//             sortable: true,
//         },
//     ];

//     const userFollowupShow = async (id) => {
//         try {
//             const response = await axios.post(`${host}/GetByIdLead`, {
//                 id: id,
//             });
//             setApiResponse(response.data);
//             if (response.data.data.Filing_Process.length > 0) {
//                 for (let i = response.data.data.Filing_Process.length - 1; i >= 0; i--) {
//                     const val = response.data.data.Filing_Process[i];
//                     if (val.caseInitiated == 'Case Initiated') {
//                         setCaseInitiatedChecked(true);
//                     }
//                     if (val.caseInitiated == "true" && val.fileIntiated == 'File Initiated or docs checklist sent') {
//                         setFileIntiatedChecked(true);
//                     }
//                     if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'Docs received & Pending docs sent') {
//                         setDocsReceivedChecked(true);
//                     }
//                     if (val.caseInitiated == "true" && val.fileIntiated == "true" && val.docsReceived == "true" && val.sopprepration == 'Sop Or letters prepration & Forms prep') {
//                         setSoppreprationChecked(true);
//                     }
//                     if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'SOP or Letters sent to client') {
//                         setSopLettersChecked(true);
//                     }
//                     if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'Confirmation received on SOP, letters and docs, Forms confirmation') {
//                         setConfirmRecievedChecked(true);
//                     }
//                     if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'File sent for Upload or submission') {
//                         setFilesentChecked(true);
//                     }
//                     if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'Visa approved') {
//                         setVisaapprovedChecked(true);
//                     }
//                     if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'true' && val.visarefusal == 'Visa refusal') {
//                         setVisaRefusalChecked(true);
//                     }
//                 }
//             } else {
//                 console.log("Not find any filling proccess");
//             }
//         } catch (error) {
//             console.error(error);
//         }
//         setFollowup(true)
//     };

//     const followupClose = () => {
//         setFollowup(false);
//         setCaseInitiatedChecked(false);
//         setFileIntiatedChecked(false);
//         setDocsReceivedChecked(false);
//         setSoppreprationChecked(false);
//         setSopLettersChecked(false);
//         setConfirmRecievedChecked(false);
//         setFilesentChecked(false);
//         setVisaapprovedChecked(false);
//         setVisaRefusalChecked(false);
//     };

//     if (apiResponse && apiResponse.data) {
//         var followUpStatus = apiResponse.data
//         const followUpDates = apiResponse.data.Followup_Date_fiiling_team;
//         var followupdatepayment = apiResponse.data.Followup_Date

//         const NextFollowupDate = apiResponse.data.Next_Followup_Date_fiiling_team;
//         const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };

//         // Format regular follow-up dates
//         var formattedFollowUpDates = followUpDates.map(dateString => {
//             if (dateString) {
//                 const followUpDate = new Date(dateString);
//                 return followUpDate.toLocaleDateString('en-US', options);
//             } else {
//                 return null;
//             }
//         });
//         var followupdatepaymentData = followupdatepayment.map(dateStringpayment => {
//             if (dateStringpayment) {
//                 const followUpDatepayment = new Date(dateStringpayment);
//                 return followUpDatepayment.toLocaleDateString('en-US', options);
//             } else {
//                 return null;
//             }
//         });
//         // Format Next Follow-up Date
//         var formattedNextFollowupDate = NextFollowupDate.map(dateStringnext => {
//             if (dateStringnext) {
//                 const followUpDateNext = new Date(dateStringnext);
//                 return followUpDateNext.toLocaleDateString('en-US', options);
//             } else {
//                 return null;
//             }
//         });
//         var notes = apiResponse.data.Notes_fiiling_team
//         var TotalAmount = apiResponse.data.Total_Amount
//     }

//     const handleItemsPerPageChange = (event) => {
//         const newItemsPerPage = parseInt(event.target.value, 10);
//         setItemsPerPage(newItemsPerPage);
//         setCurrentPage(1); // Reset current page when changing items per page
//         // Call function to fetch data with the new items per page setting
//     };

//     const handlePageChange = (page) => {
//         setCurrentPage(page);
//     };

//     const formatLeadDate = (dateString) => {
//         const date = new Date(dateString);
//         const day = String(date.getDate()).padStart(2, '0');
//         const month = String(date.getMonth() + 1).padStart(2, '0');
//         const year = String(date.getFullYear());

//         return `${day}-${month}-${year}`;
//     };

//     const formatLeadTime = (timeString) => {
//         const time = new Date(timeString);
//         const hours = String(time.getHours()).padStart(2, '0');
//         const minutes = String(time.getMinutes()).padStart(2, '0');
//         const seconds = String(time.getSeconds()).padStart(2, '0');

//         return `${hours}:${minutes}:${seconds}`;
//     };

//     const handleSearch = (e) => {
//         setSearchText(e.target.value);
//     };

//     const formatDate = (date) => {
//         const year = date.getFullYear();
//         const month = String(date.getMonth() + 1).padStart(2, '0');
//         const day = String(date.getDate()).padStart(2, '0');
//         return `${year}-${month}-${day}`;
//     };

//     const filterData =
//         data && data.data
//             ? data.data.filter((row) => {
//                 return (
//                     Object.values(row).some(
//                         (value) =>
//                             typeof value === 'string' &&
//                             value.toLowerCase().includes(searchText.toLowerCase())
//                     )
//                 );
//                 return false;
//             })
//             : [];


//     return (
//         <>
//             <Sidebar />
//             <CustomNavbar />
//             <div className="main-content">
//                 <div className="container-fluid">
//                     <div className="topbox">
//                         <div className="row align-items-center">
//                             <div className="col-md-8">
//                                 <div className="left-logo">
//                                     <div>
//                                         <img src={profile2} alt="Logo" className="logo" />
//                                     </div>
//                                     <p className="topboxttex">Lead Management</p>
//                                 </div>
//                             </div>
//                             <div className="col-md-4 ">
//                                 <nav aria-label="breadcrumb">
//                                     <ol className="breadcrumb mb-0">
//                                         <li className="breadcrumb-item">
//                                             <a href="dashboard">Dashboard</a>
//                                         </li>
//                                         <li className="breadcrumb-item active" aria-current="page">
//                                             Leads
//                                         </li>
//                                     </ol>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="row pt-3 my-3">
//                         <div className="col-md-12">
//                             <div className="wrap mt-0" >
//                                 <div style={{ display: "flex" }}>
//                                     <div className="search">
//                                         <input
//                                             type="text"
//                                             value={searchText}
//                                             onChange={handleSearch}
//                                             placeholder="Search"
//                                             style={{ borderRadius: "7px", height: "35px", marginTop: "20px", borderColor: "#180A4C" }}
//                                         />
//                                     </div>
//                                     <div>
//                                         <label>Start Date:</label>
//                                         <input
//                                             type="date"
//                                             value={startDate}
//                                             onChange={(e) => setStartDate(e.target.value)}
//                                             className="datepick"
//                                         />
//                                     </div>
//                                     <div>
//                                         <label>End Date:</label>
//                                         <input
//                                             type="date"
//                                             value={endDate}
//                                             onChange={(e) => setEndDate(e.target.value)}
//                                             className="datepick"
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="col-lg-12">
//                             <div className="row tab-content">
//                                 <div
//                                     id="list-view"
//                                     className="tab-pane fade active show col-lg-12"
//                                 >
//                                     <div className="card">
//                                         <div className="card-header">
//                                             <DataTable
//                                                 title="Visa refusal"
//                                                 className="custom-data-table"
//                                                 columns={columns}
//                                                 data={data}
//                                                 // pagination
//                                                 // paginationTotalRows={data.length}
//                                                 // onChangePage={handlePageChange}
//                                                 // paginationPerPage={perPage}
//                                                 // paginationRowsPerPageOptions={[10, 20, 30]} // Customize rows per page options
//                                                 // onChangeRowsPerPage={handlePerPageChange}
//                                                 paginationPerPageLabel="Rows per page"
//                                             />

//                                             {/* Pagination */}
//                                             <div style={{ display: "flex", justifyContent: "flex-end" }}>
//                                                 <span>Rows per page:</span>
//                                                 <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
//                                                     <option value={10}>10</option>
//                                                     <option value={20}>20</option>
//                                                     <option value={30}>30</option>
//                                                 </select>
//                                                 <span>{`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage)} of ${totalRows}`}</span>
//                                                 <div>
//                                                     <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
//                                                         Previous Page
//                                                     </button>
//                                                     <button onClick={() => handlePageChange(currentPage + 1)} disabled={((currentPage - 1) * itemsPerPage + 1) >= totalRows}>
//                                                         Next Page
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div>
//                 <Offcanvas
//                     className="profileCanvas"
//                     show={followup}
//                     onHide={followupClose}
//                     placement={"end"}
//                 >
//                     <Offcanvas.Header closeButton>
//                         <Offcanvas.Title>
//                             {apiResponse && apiResponse.data && (
//                                 <div className="profilFlex">
//                                     <div className="prbg">
//                                         <i className="fa-solid fa-user" />
//                                     </div>
//                                     <h6 className="prftitle">{apiResponse.data.first_name}  {apiResponse.data.last_name} </h6>
//                                 </div>
//                             )}
//                         </Offcanvas.Title>
//                     </Offcanvas.Header>
//                     <Offcanvas.Body>
//                         <form action="">
//                             {apiResponse && apiResponse.data && (
//                                 <div className="container">
//                                     <div className="row">
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Name</h6>
//                                                 <p>{apiResponse.data.first_name}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Gender</h6>
//                                                 <p>{apiResponse.data.gender}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Email</h6>
//                                                 <p>{apiResponse.data.email}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Age</h6>
//                                                 <p>{apiResponse.data.age == "undefined" ? "" : apiResponse.data.age}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Contact No.</h6>
//                                                 <p>{apiResponse.data.contact_number}</p>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Alt. Contact No. </h6>
//                                                 <p>{apiResponse.data.alt_contact_number}</p>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Country</h6>
//                                                 <p>{apiResponse.data.country_name}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>State</h6>
//                                                 <p>{apiResponse.data.state_name}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Education</h6>
//                                                 <p>{apiResponse.data.education}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Note</h6>
//                                                 <p>{apiResponse.data.note}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Services</h6>
//                                                 <p>{apiResponse.data.Services}</p>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className="namedetail">
//                                                 <h6>Upload File</h6>
//                                                 {apiResponse.data.Upload_File == null ? "" : <img src={`https://api.checkcheckservices.in/public/${apiResponse.data.Upload_File}`} style={{ width: "150px" }} />}
//                                             </div>
//                                         </div>
//                                         <hr />
//                                     </div>
//                                     <div className="rowv mt-2">
//                                         <div className="col-md-12">
//                                             <div className="mytabpill">
//                                                 <ul
//                                                     className="nav nav-pills nav-justified my-2"
//                                                     id="myTabs"
//                                                     role="tablist"
//                                                 >
//                                                     <li className="nav-item">
//                                                         <a
//                                                             className="nav-link active"
//                                                             id="commentary-tab"
//                                                             data-bs-toggle="pill"
//                                                             href="#Commentary"
//                                                             role="tab"
//                                                         >
//                                                             Details
//                                                         </a>
//                                                     </li>
//                                                     <li className="nav-item">
//                                                         <a
//                                                             className="nav-link"
//                                                             id="videos-tab"
//                                                             data-bs-toggle="pill"
//                                                             href="#Videos"
//                                                             role="tab"
//                                                         >
//                                                             Follow-ups
//                                                         </a>
//                                                     </li>
//                                                     <li className="nav-item">
//                                                         <a
//                                                             className="nav-link"
//                                                             id="events-tab"
//                                                             data-bs-toggle="pill"
//                                                             href="#Events"
//                                                             role="tab"
//                                                         >
//                                                             Payment
//                                                         </a>
//                                                     </li>

//                                                     <li className="nav-item">
//                                                         <a
//                                                             className="nav-link"
//                                                             id="filing-tab"
//                                                             data-bs-toggle="pill"
//                                                             href="#Filing"
//                                                             role="tab"
//                                                         >
//                                                             Filing Process
//                                                         </a>
//                                                     </li>
//                                                 </ul>
//                                                 <div className="tab-content">
//                                                     <div
//                                                         className="tab-pane fade show active"
//                                                         id="Commentary"
//                                                         role="tabpanel"
//                                                     >
//                                                         <div className="row">
//                                                             <div className="col-md-12 my-3">
//                                                                 <div className="accrordiondiv">
//                                                                     <div
//                                                                         className="accordion"
//                                                                         id="accordionExample"
//                                                                     >
//                                                                         <div className="accordion-item">
//                                                                             <h2
//                                                                                 className="accordion-header"
//                                                                                 id="headingOne"
//                                                                             >
//                                                                                 <button
//                                                                                     className="accordion-button collapsed"
//                                                                                     type="button"
//                                                                                     data-bs-toggle="collapse"
//                                                                                     data-bs-target="#collapseOne"
//                                                                                     aria-expanded="true"
//                                                                                     aria-controls="collapseOne"
//                                                                                 >
//                                                                                     Lead Information
//                                                                                 </button>
//                                                                             </h2>
//                                                                             <div
//                                                                                 id="collapseOne"
//                                                                                 className="accordion-collapse collapse"
//                                                                                 aria-labelledby="headingOne"
//                                                                                 data-bs-parent="#accordionExample"
//                                                                             >
//                                                                                 <div className="accordion-body">
//                                                                                     <table className="table">
//                                                                                         <tbody>
//                                                                                             <tr>
//                                                                                                 <th>Lead Status</th>
//                                                                                                 <td>{apiResponse.data.status_convert[apiResponse.data.status_convert.length - 1]}</td>
//                                                                                             </tr>

//                                                                                             <tr>
//                                                                                                 <th>Lead Type</th>
//                                                                                                 <td>{apiResponse.data.Lead_Type}</td>
//                                                                                             </tr>
//                                                                                             <tr>
//                                                                                                 <th>Lead Source</th>
//                                                                                                 <td>{apiResponse.data.Lead_Source}</td>
//                                                                                             </tr>
//                                                                                             <tr>
//                                                                                                 <th>Lead Assign Sales</th>
//                                                                                                 <td>{apiResponse.data.Lead_AssignName == null ? "N/A" : apiResponse.data.Lead_AssignName}</td>
//                                                                                             </tr>
//                                                                                             <tr>
//                                                                                                 <th>Lead Assign Filling Manager</th>
//                                                                                                 <td>{apiResponse.data.Filing_ManagerName == null ? "N/A" : apiResponse.data.Filing_ManagerName}</td>
//                                                                                             </tr>
//                                                                                             <tr>
//                                                                                                 <th>Lead Assign Filling Team</th>
//                                                                                                 <td>{apiResponse.data.Filing_TeamName == null ? "N/A" : apiResponse.data.Filing_TeamName}</td>
//                                                                                             </tr>
//                                                                                         </tbody>
//                                                                                     </table>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div
//                                                         className="tab-pane fade"
//                                                         id="Videos"
//                                                         role="tabpanel"
//                                                     >
//                                                         <div className="row">
//                                                             <div className="col-md-12 mb-3">
//                                                                 <div className="table-responsive tablebg">
//                                                                     <table className="table">
//                                                                         <tbody>
//                                                                             <tr>
//                                                                                 <th>Followup Status</th>
//                                                                                 <th>Follow up Date</th>
//                                                                                 <th>Note</th>
//                                                                                 <th>Next Follow up Date</th>
//                                                                             </tr>
//                                                                             {followUpStatus.Followup_Date_fiiling_team.slice().reverse().map((date, index) => (
//                                                                                 <tr key={index}>
//                                                                                     <td>{followUpStatus.Filing_Team_Status[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
//                                                                                     <td>{formatLeadDate(date)} {formatLeadTime(date)}</td>
//                                                                                     <td>{followUpStatus.Notes_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
//                                                                                     <td>{followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]) : ""}</td>
//                                                                                 </tr>
//                                                                             ))}
//                                                                             {followUpStatus.Followup_Date.slice().reverse().map((formattedDate, index) => (
//                                                                                 <tr key={index}>
//                                                                                     <td>{followUpStatus.status_convert[followUpStatus.Followup_Date.length - 1 - index]}</td>
//                                                                                     <td>{formatLeadDate(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])} {formatLeadTime(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])}</td>
//                                                                                     <td>{followUpStatus.Notes_sales[followUpStatus.Followup_Date.length - 1 - index]}</td>
//                                                                                     <td>{followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index]) : ""}</td>
//                                                                                 </tr>
//                                                                             ))}
//                                                                         </tbody>
//                                                                     </table>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div
//                                                         className="tab-pane fade"
//                                                         id="Events"
//                                                         role="tabpanel"
//                                                     >
//                                                         <div className="row justify-content-center">
//                                                             <div className="col-md-3">
//                                                                 <div className="namedetail">
//                                                                     <h6>Total Payment</h6>
//                                                                     {apiResponse.data.status_convert.includes('Convert') ? (
//                                                                         <p>{followUpStatus.Total_Amount.slice().reverse().map((formattedDate, index) => (
//                                                                             <tr key={index}>
//                                                                                 <td>${formattedDate}</td>
//                                                                             </tr>
//                                                                         ))}</p>
//                                                                     ) : (
//                                                                         <p>N/A</p>
//                                                                     )}
//                                                                 </div>
//                                                             </div>

//                                                             <div className="col-md-3">
//                                                                 <div className="namedetail">
//                                                                     <h6>Payment Received</h6>
//                                                                     {apiResponse.data.status_convert.includes('Convert') ? (
//                                                                         <p>{followUpStatus.Amount_Paid.slice().reverse().map((formattedDate, index) => (
//                                                                             <tr key={index}>
//                                                                                 <td>${formattedDate}</td>
//                                                                             </tr>
//                                                                         ))}</p>
//                                                                     ) : (
//                                                                         <p>N/A</p>
//                                                                     )}
//                                                                 </div>
//                                                             </div>

//                                                             <div className="col-md-3">
//                                                                 <div className="namedetail">
//                                                                     <h6>Remaining Payment</h6>
//                                                                     {apiResponse.data.status_convert.includes('Convert') ? (
//                                                                         <p>{followUpStatus.Amount_Due.slice().reverse().map((formattedDate, index) => (
//                                                                             <tr key={index}>
//                                                                                 <td>${formattedDate}</td>
//                                                                             </tr>
//                                                                         ))}</p>
//                                                                     ) : (
//                                                                         <p>N/A</p>
//                                                                     )}
//                                                                 </div>
//                                                             </div>
//                                                         </div>

//                                                         <div className="row">
//                                                             <div className="col-md-12 mb-3">
//                                                                 <div className="table-responsive tablebg">
//                                                                     <table className="table">
//                                                                         <tbody>
//                                                                             <tr>
//                                                                                 <th>Date</th>
//                                                                                 <th>Amount</th>
//                                                                                 <th>Proof</th>
//                                                                             </tr>

//                                                                             {apiResponse.data.status_convert.includes('Convert') ? (
//                                                                                 followUpStatus.Payment_Proof_Date.slice().reverse().map((formattedDate, index) => (
//                                                                                     <tr key={index}>
//                                                                                         <td>{formatLeadDate(formattedDate)}</td>
//                                                                                         <td>{followUpStatus.Amount_Paid[followUpStatus.Payment_Proof_Date.length - 1 - index]}</td>
//                                                                                         <td>
//                                                                                             <a href={`https://api.checkcheckservices.in/public/${followUpStatus.Upload_Payment_Proof[followUpStatus.Payment_Proof_Date.length - 1 - index]}`} download={`PaymentProof-${index}`} target="_blank" >
//                                                                                                 View
//                                                                                             </a>
//                                                                                         </td>
//                                                                                     </tr>
//                                                                                 ))
//                                                                             ) : (
//                                                                                 <p></p>
//                                                                             )}

//                                                                         </tbody>
//                                                                     </table>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                     <div
//                                                         className="tab-pane fade"
//                                                         id="Filing"
//                                                         role="tabpanel"
//                                                     >
//                                                         <div className="container">
//                                                             <div className="row">
//                                                                 <div className="col-md-12 my-3">
//                                                                     <div className="accrordiondiv">
//                                                                         <div
//                                                                             className="accordion"
//                                                                             id="accordionExample2"
//                                                                         >
//                                                                             <div className="accordion-item">
//                                                                                 <h2
//                                                                                     className="accordion-header"
//                                                                                     id="headingOne"
//                                                                                 >
//                                                                                     <button
//                                                                                         className="accordion-button collapsed"
//                                                                                         type="button"
//                                                                                         data-bs-toggle="collapse"
//                                                                                         data-bs-target="#collapseOne"
//                                                                                         aria-expanded="true"
//                                                                                         aria-controls="collapseOne"
//                                                                                     >
//                                                                                         Services
//                                                                                     </button>
//                                                                                 </h2>
//                                                                                 <div
//                                                                                     id="collapseOne"
//                                                                                     className="accordion-collapse collapse"
//                                                                                     aria-labelledby="headingOne"
//                                                                                     data-bs-parent="#accordionExample2"
//                                                                                 >
//                                                                                     <div className="accordion-body">
//                                                                                         {serviceName.data && serviceName.data.length > 0 ? (
//                                                                                             serviceName.data.map((val, key) => (
//                                                                                                 <div className="form-check">
//                                                                                                     <input
//                                                                                                         className="form-check-input"
//                                                                                                         type="radio"
//                                                                                                         name="flexRadioDefault"
//                                                                                                         id="flexRadioDefault1"
//                                                                                                         checked={apiResponse.data.Services === val.service_name}

//                                                                                                     />
//                                                                                                     <label
//                                                                                                         className="form-check-label forcheckfont"
//                                                                                                         htmlfor="flexRadioDefault1"
//                                                                                                     >
//                                                                                                         {val.service_name}
//                                                                                                     </label>
//                                                                                                 </div>
//                                                                                             ))
//                                                                                         ) : null}
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>

//                                                                             <div className="accordion-item">
//                                                                                 <h2
//                                                                                     className="accordion-header"
//                                                                                     id="headingTwo"
//                                                                                 >
//                                                                                     <button
//                                                                                         class="accordion-button collapsed"
//                                                                                         type="button"
//                                                                                         data-bs-toggle="collapse"
//                                                                                         data-bs-target="#collapseTwo"
//                                                                                         aria-expanded="false"
//                                                                                         aria-controls="collapseTwo"
//                                                                                     >
//                                                                                         Filing Status
//                                                                                     </button>
//                                                                                 </h2>
//                                                                                 <div
//                                                                                     id="collapseTwo"
//                                                                                     className="accordion-collapse collapse"
//                                                                                     aria-labelledby="headingTwo"
//                                                                                     data-bs-parent="#accordionExample2"
//                                                                                 >
//                                                                                     <div className="accordion-body accrfp">
//                                                                                         <div className="form-check frmch">
//                                                                                             <input
//                                                                                                 className="form-check-input"
//                                                                                                 type="checkbox"
//                                                                                                 defaultValue
//                                                                                                 id="flexCheckDefault"
//                                                                                                 name="caseInitiated"
//                                                                                                 checked={caseInitiatedChecked}
//                                                                                                 disabled
//                                                                                             />
//                                                                                             <div className="caseinitiate">
//                                                                                                 <h6>Case Initiated</h6>
//                                                                                                 {/* <p>03 Nov 23, 01:00 PM</p> */}
//                                                                                             </div>
//                                                                                         </div>

//                                                                                         <div className="form-check frmch">
//                                                                                             <input
//                                                                                                 className="form-check-input"
//                                                                                                 type="checkbox"
//                                                                                                 defaultValue
//                                                                                                 id="flexCheckDefault"
//                                                                                                 name="fileIntiated"
//                                                                                                 checked={fileInitiatedChecked}
//                                                                                                 disabled
//                                                                                             />
//                                                                                             <div className="caseinitiate">
//                                                                                                 <h6>
//                                                                                                     File Initiated or docs checklist
//                                                                                                     sent
//                                                                                                 </h6>
//                                                                                                 {/* <p>03 Nov 23, 01:00 PM</p> */}
//                                                                                             </div>
//                                                                                         </div>

//                                                                                         <div className="form-check frmch">
//                                                                                             <input
//                                                                                                 className="form-check-input"
//                                                                                                 type="checkbox"
//                                                                                                 defaultValue
//                                                                                                 id="flexCheckDefault"
//                                                                                                 name="docsReceived"
//                                                                                                 checked={docReceivedChecked}
//                                                                                                 disabled
//                                                                                             />
//                                                                                             <div className="caseinitiate">
//                                                                                                 <h6>
//                                                                                                     Docs received & Pending docs
//                                                                                                     sent{" "}
//                                                                                                 </h6>
//                                                                                                 {/* <p>03 Nov 23, 01:00 PM</p> */}
//                                                                                             </div>
//                                                                                         </div>

//                                                                                         <div className="form-check frmch">
//                                                                                             <input
//                                                                                                 className="form-check-input"
//                                                                                                 type="checkbox"
//                                                                                                 defaultValue
//                                                                                                 id="flexCheckDefault"
//                                                                                                 name="sopprepration"
//                                                                                                 checked={soppreprationChecked}
//                                                                                                 disabled
//                                                                                             />
//                                                                                             <div className="caseinitiate">
//                                                                                                 <h6>
//                                                                                                     Sop Or letters prepration &
//                                                                                                     Forms prep
//                                                                                                 </h6>
//                                                                                                 {/* <p>03 Nov 23, 01:00 PM</p> */}
//                                                                                             </div>
//                                                                                         </div>

//                                                                                         <div className="form-check frmch">
//                                                                                             <input
//                                                                                                 className="form-check-input"
//                                                                                                 type="checkbox"
//                                                                                                 defaultValue
//                                                                                                 id="flexCheckDefault"
//                                                                                                 name="sopletters"
//                                                                                                 checked={sopLetterChecked}
//                                                                                                 disabled
//                                                                                             />
//                                                                                             <div className="caseinitiate">
//                                                                                                 <h6>
//                                                                                                     SOP or Letters sent to client{" "}
//                                                                                                 </h6>
//                                                                                                 {/* <p>03 Nov 23, 01:00 PM</p> */}
//                                                                                             </div>
//                                                                                         </div>

//                                                                                         <div className="form-check frmch">
//                                                                                             <input
//                                                                                                 className="form-check-input"
//                                                                                                 type="checkbox"
//                                                                                                 defaultValue
//                                                                                                 id="flexCheckDefault"
//                                                                                                 name="confirmrecieved"
//                                                                                                 checked={confirmReceivedChecked}
//                                                                                                 disabled
//                                                                                             />
//                                                                                             <div className="caseinitiate">
//                                                                                                 <h6>
//                                                                                                     Confirmation received on SOP,
//                                                                                                     letters and docs, Forms
//                                                                                                     confirmation{" "}
//                                                                                                 </h6>
//                                                                                                 {/* <p>03 Nov 23, 01:00 PM</p> */}
//                                                                                             </div>
//                                                                                         </div>

//                                                                                         <div className="form-check frmch">
//                                                                                             <input
//                                                                                                 className="form-check-input"
//                                                                                                 type="checkbox"
//                                                                                                 defaultValue
//                                                                                                 id="flexCheckDefault"
//                                                                                                 name="filesent"
//                                                                                                 checked={fileSentChecked}
//                                                                                                 disabled
//                                                                                             />
//                                                                                             <div className="caseinitiate">
//                                                                                                 <h6>
//                                                                                                     File sent for Upload or
//                                                                                                     submission
//                                                                                                 </h6>
//                                                                                                 {/* <p>03 Nov 23, 01:00 PM</p> */}
//                                                                                             </div>
//                                                                                         </div>

//                                                                                         <div className="form-check frmch">
//                                                                                             <input
//                                                                                                 className="form-check-input"
//                                                                                                 type="checkbox"
//                                                                                                 defaultValue
//                                                                                                 id="flexCheckDefault"
//                                                                                                 name="visaapproved"
//                                                                                                 checked={visaApprovedChecked}
//                                                                                                 disabled
//                                                                                             />
//                                                                                             <div className="caseinitiate">
//                                                                                                 <h6>Visa approved</h6>
//                                                                                                 {/* <p>03 Nov 23, 01:00 PM</p> */}
//                                                                                             </div>
//                                                                                         </div>

//                                                                                         <div className="form-check frmch">
//                                                                                             <input
//                                                                                                 className="form-check-input"
//                                                                                                 type="checkbox"
//                                                                                                 defaultValue
//                                                                                                 id="flexCheckDefault"
//                                                                                                 name="visarefusal"
//                                                                                                 checked={visaRefusalChecked}
//                                                                                                 disabled
//                                                                                             />
//                                                                                             <div className="caseinitiate">
//                                                                                                 <h6>Visa refusal</h6>
//                                                                                                 {/* <p>03 Nov 23, 01:00 PM</p> */}
//                                                                                             </div>
//                                                                                         </div>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             )}
//                         </form>
//                     </Offcanvas.Body>
//                 </Offcanvas>
//             </div>

//         </>
//     );
// }
