import React, { useState, useEffect } from 'react';
import Offcanvas from "react-bootstrap/Offcanvas";
import withReactContent from "sweetalert2-react-content";
import 'react-phone-input-2/lib/style.css';
import { useForm } from 'react-hook-form';
import axios from "axios";
import Swal from "sweetalert2";
import host from "./utils";
import "./term$condition.css";

const TermAndCondition = () => {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [id, setId] = useState("");
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        const url = new URL(window.location.href);
        const id = url.searchParams.get("id");
        setId(id);
        fetchLeadById(id);
    }, [])

    const fetchLeadById = async (id) => {
        try {
            const response = await axios.post(`${host}/GetByIdLead`, {
                id: id,
            });
            if (response.status === 200) {
                if (response.data.data.term_condition_status == "true") {
                    // window.location = "http://192.168.29.47:3002/thank-you";
                    window.location = "https://checkcheckservices.in/thank-you";
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (termsAccepted) {
            const data = {
                _id: id,
                term_condition_status: termsAccepted
            }
            axios.post(`${host}/update-termcondition`, data)
                .then(response => {
                    if (response.data.error == true) {
                        MySwal.fire({
                            icon: "warning",
                            title: "Warning!",
                            text: `${response.data.message}`,
                        });
                    } else {
                        MySwal.fire({
                            icon: "success",
                            title: "Success!",
                            text: `${response.data.message}`,
                        });
                        // window.location = "http://192.168.29.47:3002/thank-you";
                        window.location = "https://checkcheckservices.in/thank-you";
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } else {
            // Display an error message or handle the case where terms are not accepted
            alert("Please accept the terms and conditions.");
        }
    };

    return (
        <>
            <div className="terms-container">
                <Offcanvas.Body bsPrefix="offcanvas-padding-body">
                    <form onSubmit={onSubmit}>
                        <div className="modal-body modal-padding">
                            <div className="row">
                                <div className="col-md-12 mb-2 bg-color">
                                    <h2>Terms & Conditions</h2>
                                    <p>Requires digital approval</p>
                                </div>
                                <div className="terms-list">
                                    <ul>
                                        <li>No refund policy: Services provided are non-refundable. A client can ask for a refund only in case we deny the services promised or in case of negligence on our part.</li>
                                        <li>We hold no responsibility for documents provided by the client for visa filing. In case of a fake document, it is the client’s responsibility as each document is at the client’s discretion.</li>
                                        <li>In case of offensive or abusive behavior, or any activity amounting to defamation towards the company can result in legal action against the client.</li>
                                        <li>Acceptance or refusal of visa is solely at the discretion of the embassy. We'll be able to work towards making your case strong. The company is not liable for the embassy’s decision unless it is due to negligence on our part as mentioned in the refusal letter.</li>
                                        <li>We're obliged to offer you uninterrupted services and attend to your calls and messages in the time. ( Specified office hours)</li>
                                        <li>Check Services may use client's information for marketing purposes like Facebook, newspaper, or magazine advertisements and display of names at check check services office.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="termsCheckbox"
                                checked={termsAccepted}
                                onClick={() => setTermsAccepted(!termsAccepted)}
                            />
                            <label htmlFor="termsCheckbox">
                                I accept the terms and conditions
                            </label>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary me-2">
                                Accept
                            </button>
                        </div>
                    </form>
                </Offcanvas.Body>
            </div>
        </>
    )
}

export default TermAndCondition;