const infomationLoginUser = (req, res) => {
    let checksalestoken = localStorage.getItem('SalesToken');
    let checkfmtoken = localStorage.getItem('FilingManagerToken');
    let checkPstoken = localStorage.getItem('Pre_SalesToken');
    let checkFttoken = localStorage.getItem('FilingTeamToken');
    let checkInfluencerToken = localStorage.getItem("InfluencerToken");
    let checkAdmintoken = localStorage.getItem('Admintoken');

    if (checksalestoken != null) {
        const token = checksalestoken;
        const [header, payload, signature] = token.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        const userId = decodedPayload.userId;
        localStorage.setItem('userId', userId);
    }
    if (checkfmtoken != null) {
        const token = checkfmtoken;
        const [header, payload, signature] = token.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        const userId = decodedPayload.userId;
        localStorage.setItem('userId', userId);
    }
    if (checkPstoken != null) {
        const token = checkPstoken;
        const [header, payload, signature] = token.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        const userId = decodedPayload.userId;
        localStorage.setItem('userId', userId);
    }
    if (checkFttoken != null) {
        const token = checkFttoken;
        const [header, payload, signature] = token.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        const userId = decodedPayload.userId;
        localStorage.setItem('userId', userId);
    }
    if (checkInfluencerToken != null) {
        const token = checkInfluencerToken;
        const [header, payload, signature] = token.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        const userId = decodedPayload.userId;
        localStorage.setItem('userId', userId);
    }
    if (checkAdmintoken != null) {
        const token = checkAdmintoken;
        const [header, payload, signature] = token.split('.');
        const decodedPayload = JSON.parse(atob(payload));
        const userId = decodedPayload.userId;
        localStorage.setItem('userId', userId);
    }
}
export default infomationLoginUser