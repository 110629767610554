import React, { useState, useEffect } from "react";
import 'react-phone-input-2/lib/style.css';
import axios from "axios";
import icondash1 from "../Assets/Images/icondash1.png";
import Swal from "sweetalert2";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import checklogin from "./checkLogin";

const Analytics = () => {
    const [countries, setCountries] = useState([]);
    const [leadTypeList, setLeadTypeList] = useState([]);
    const [leadSourceList, setLeadSourceList] = useState([]);
    const [formData, setFormData] = useState({
        gender: '',
        education: '',
        country: '',
        Proficiency_Test: '',
        Lead_Type: '',
        Lead_Source: ''
    });
    const [leadCount, setLeadCount] = useState(0);

    useEffect(() => {
        checklogin();
        fetchCountries();
        fetchLeadType();
        fetchLeadSource();
    }, [])

    const fetchCountries = async () => {
        try {
            const response = await axios.get(`${host}/list-country`);
            if (response.data.error == false) {
                setCountries(response.data.data)
            }
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchLeadType = async () => {
        try {
            const response = await axios.get(`${host}/listlead-type`);
            if (response.data.error == false) {
                setLeadTypeList(response.data);
            }
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchLeadSource = async () => {
        try {
            const response = await axios.get(`${host}/listlead-source`);
            if (response.data.error == false) {
                setLeadSourceList(response.data);
            }
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        fetchAnalytics();
    }, [formData])

    const fetchAnalytics = async (req, res) => {
        try {
            const response = await axios.get(`${host}/getanalytics-count`, {
                params: {
                    gender: formData.gender,
                    education: formData.education,
                    country: formData.country,
                    Proficiency_Test: formData.Proficiency_Test,
                    Lead_Type: formData.Lead_Type,
                    Lead_Source: formData.Lead_Source
                }
            });
            if (response.data.error == false) {
                setLeadCount(response.data.data)
            }
        } catch (error) {
            return res.status(500).json({ error: true, message: "Internal Server Error", data: null });
        }
    }

    return (
        <>
            <Sidebar />
            <CustomNavbar />
            <div className="main-content">
                <div className="container-fluid">
                    <div className="topbox">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <div className="left-logo">
                                    <div>
                                        <img src={profile2} alt="Logo" className="logo" />
                                    </div>
                                    <p className="topboxttex">Leader Board</p>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="dashboard">Dashboard</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Leads
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 pt-3 my-3">
                            <div className="d-flex align-items-center  justify-content-between">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Gender</label>
                                        <br />
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>

                                    <div className="col-md-4">
                                        <label>Education</label>
                                        <br />
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="education"
                                            value={formData.education}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="10 TH">10 TH</option>
                                            <option value="12 TH">12 TH</option>
                                            <option value="Gradute">Gradute</option>
                                            <option value="Diploma">Diploma</option>
                                            <option value="Post Gradute">Post Gradute</option>
                                            <option value="Post Gradute and Above">Post Gradute and Above</option>

                                        </select>
                                    </div>

                                    <div className="col-md-4">
                                        <label>Country</label>
                                        <br />
                                        <select
                                            className="form-control"
                                            aria-label="Default select example"
                                            name="country"
                                            value={formData.country}
                                            onChange={handleChange}
                                        >
                                            <option value="">
                                                Select
                                            </option>
                                            {countries.map((country) => (
                                                <option value={country.short_name}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-4">
                                        <label>Have you taken Any Proficiency Test? </label>
                                        <br />
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="Proficiency_Test"
                                            value={formData.Proficiency_Test}
                                            onChange={handleChange}
                                        >
                                            <option value="" >
                                                Select
                                            </option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label>Lead Type</label>
                                        <br />
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="Lead_Type"
                                            value={formData.Lead_Type}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            {leadTypeList.data && leadTypeList.data.length > 0 ? (
                                                leadTypeList.data.map((val, key) => (
                                                    <option value={val.lead_type}>{val.lead_type}</option>
                                                ))
                                            ) : null}
                                        </select>
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label>Lead Source</label>
                                        <br />
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="Lead_Source"
                                            value={formData.Lead_Source}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select</option>
                                            {leadSourceList.data && leadSourceList.data.length > 0 ? (
                                                leadSourceList.data.map((val, key) => (
                                                    <option value={val.lead_source}>{val.lead_source}</option>
                                                ))
                                            ) : null}
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row tab-content">
                                <div className="card">
                                    <div className="card-header">
                                        <div key="" className="col-md-3 mb-3">
                                            <a href="/analytics-list">
                                                <div className="card radius-10  border-0 border-4 cardbg-orange ">
                                                    <div className="card-body">
                                                        <div className="d-flx-dist">
                                                            <div>
                                                                <div className="dgl2">
                                                                    <img src={icondash1} alt="img" />
                                                                </div>
                                                                <p className="dist-title">Leads</p>
                                                            </div>
                                                            <div>
                                                                <h4 className="dist-title">{leadCount}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Analytics;