import React, { useState, useEffect } from 'react';
import "./login.css"
import utils from "../Components/utils"
import axios from 'axios';
import withReactContent from 'sweetalert2-react-content';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import LoginBg from "../Assets/Images/login-bg.svg";

export default function ResetPassword() {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const currentURL = window.location.href;
    const params = new URLSearchParams(new URL(currentURL).search);
    const id = params.get('id');

    const handleLogin = async (formData) => {
        const data = {
            id: id,
            password: formData.password,
            confirm_passord: formData.confirmpassword,
        };
        const response = await axios.post(`${utils}/update-password`, data)
        if (response.data.error == false) {
            MySwal.fire({
                icon: 'success',
                title: 'Success!',
                text: `${response.data.message}`,
            });
            navigate('/');
        } else {
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: `${response.data.message}`,
            });
        }
    };

    return (
        <>
            <section className='login-section'>
                <div className="container">
                    <div className="row justify-content-evenly align-items-center">
                        <div className="col-12 mb-5">
                            <div className="main-logo text-center">
                                <img src="../Assets/Images/logo.png" alt="logo" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 mb-md-0 mb-4">
                            <div className="left-side-img">
                                <img src={LoginBg} alt="login-bg" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="right-side-login-content">
                                <div className="top-text">
                                    <h4>Reset password</h4>
                                    <form onSubmit={handleSubmit(handleLogin)}>
                                        <div className="mb-4">
                                            <label htmlFor="password" className="form-label">New Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                placeholder='Enter Password'
                                                autoComplete='off'
                                                {...register('password', { required: 'Password is required' })}
                                            />
                                            {errors.password && (
                                                <span className="text-danger">{errors.password.message}</span>
                                            )}
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="confirmpassword" className="form-label">Confirm Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="confirmpassword"
                                                placeholder='Re Enter Password'
                                                autoComplete='off'
                                                {...register('confirmpassword', { required: 'Confirm Password is required' })}
                                            />
                                            {errors.confirmpassword && (
                                                <span className="text-danger">{errors.confirmpassword.message}</span>
                                            )}
                                        </div>
                                        <div className="mb-4">
                                            <button type="submit" className="w-100 login-btn">Reset Password</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
