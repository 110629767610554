import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import checklogin from "./checkLogin";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import infomationLoginUser from "./LoginUSerInfo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function FillingTodayFollowUps() {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        checklogin();
        infomationLoginUser();
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${host}/GetAllLead`);
            if (response.ok) {
                const apiData = await response.json();
                setData(apiData);
            } else {
                console.error("Failed to fetch data");
            }
        } catch (error) {
            console.error("Error during API request:", error);
        }
    };

    let paginatedData = [];
    if (Array.isArray(data)) {
        const startIndex = (currentPage - 1) * perPage;
        const endIndex = startIndex + perPage;
        paginatedData = data.slice(startIndex, endIndex);
    } else {
        console.error("Data is not an array.");
    }

    const columns = [
        {
            name: "Sr. No.",
            selector: (row, index) => index + 1 + (currentPage - 1) * perPage,
            sortable: true,
            width: '100px !important',
        },
        {
            name: "Full Name",
            selector: (row) => (
                <span className="text-info">{`${row.first_name} ${row.last_name || ""}`}</span>
            ),
            sortable: true,
            width: "200px !important"
        },
        {
            name: "Email",
            selector: (row) => row.email,

            sortable: true,
            width: "250px !important"
        },
        {
            name: "Contact no.",
            selector: (row) => row.contact_number,

            sortable: true,
        },
        {
            name: "Country.",
            selector: (row) => row.country_name,

            sortable: true,
        },
        {
            name: "Sales Executive.",
            selector: (row) => row.Lead_AssignName,
            sortable: true,
        },
        {
            name: "Lead Type.",
            selector: (row) => row.Lead_Type,

            sortable: true,
        },
        {
            name: "Gender",
            selector: (row) => row.gender,
            sortable: true,
            width: '100px !important',
        },
        {
            name: "Alternate Contact No.",
            selector: (row) => row.alt_contact_number,

            sortable: true,
        },
        {
            name: "Age.",
            selector: (row) => row.age == "undefined" ? "N/A" : row.age,

            sortable: true,
            width: '100px !important',
        },
        {
            name: "State.",
            selector: (row) => row.state_name,

            sortable: true,
        },
        {
            name: "Education.",
            selector: (row) => row.education,

            sortable: true,
        },
        {
            name: "Lead Source.",
            selector: (row) => row.Lead_Source,

            sortable: true,
        },
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
        setCurrentPage(1); // Reset to first page when changing rows per page
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleStartChange = (datetime) => {
        setStartDate(datetime);
    };

    const handleEndChange = (datetime) => {
        setEndDate(datetime);
    };

    const formatDate = (date) => {
        if (!date) return ''; // Handle null or undefined date
        const d = new Date(date);
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const filteredData =
        data && data.data
            ? data.data.filter((row) => {
                if (Array.isArray(row.Next_Followup_Date_fiiling_team) && row.Next_Followup_Date_fiiling_team.length > 0) {
                    const date = new Date();
                    const formattedDate = `${String(date.getMonth() + 1)}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
                    const val = row.Filing_Process[row.Filing_Process.length - 1]; // Get the last element
                    const startDateFormatted = new Date(startDate);
                    const endDateFormatted = new Date(endDate);
                    const formattedDates = row.Next_Followup_Date_fiiling_team.map(date => new Date(date));
                    if (startDateFormatted == '' || startDateFormatted == 'Invalid Date' || endDateFormatted == '' || endDateFormatted == 'Invalid Date') {
                        if ((val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'Visa approved or refusal')) {
                        } else {
                            return (
                                row.status == 4 &&
                                row.Filing_TeamID == localStorage.getItem('userId') &&
                                Object.values(row).some(
                                    (value) =>
                                        typeof value === 'string' &&
                                        value.toLowerCase().includes(searchText.toLowerCase())
                                )
                            );
                        }
                    } else {
                        if ((val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'Visa approved or refusal')) {
                        } else {
                            return (
                                row.status == 4 &&
                                formattedDates.some((date) => startDateFormatted <= date && date <= endDateFormatted) && formattedDates.some((date) =>
                                    startDateFormatted.getTime() <= date.getTime() &&
                                    date.getTime() <= endDateFormatted.getTime()
                                ) && row.Filing_TeamID == localStorage.getItem('userId') &&
                                Object.values(row).some(
                                    (value) =>
                                        typeof value === 'string' &&
                                        value.toLowerCase().includes(searchText.toLowerCase())
                                )
                            );
                        }
                    }
                }
                // Return false if Filing_Process doesn't exist or is empty
                return false;
            })
            : [];


    return (
        <>
            <Sidebar />
            <CustomNavbar />
            <div className="main-content">
                <div className="container-fluid">
                    <div className="topbox">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <div className="left-logo">
                                    <div>
                                        <img src={profile2} alt="Logo" className="logo" />
                                    </div>
                                    <p className="topboxttex">Lead Management</p>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item">
                                            <a href="dashboard">Dashboard</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Leads
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="row pt-3 my-3">
                        <div className="col-md-12" >
                            <div className="wrap mt-0">
                                <div style={{ display: "flex" }}>

                                    <div className="search">
                                        <input
                                            type="text"
                                            value={searchText}
                                            onChange={handleSearch}
                                            placeholder="Search"
                                            style={{ borderRadius: "7px", height: "35px", marginTop: "23px" }}
                                        />
                                    </div>
                                    {/* <div>
                                        <label>Start Date:</label>
                                        <DatePicker
                                            className="datepick"
                                            dateFormat="dd-MM-yyyy HH:mm"
                                            showTimeSelect
                                            timeIntervals={30}
                                            timeFormat="HH:mm"
                                            selected={startDate}
                                            onChange={handleStartChange}
                                        />
                                    </div>
                                    <div>
                                        <label>End Date:</label>
                                        <DatePicker
                                            className="datepick"
                                            dateFormat="dd-MM-yyyy HH:mm"
                                            showTimeSelect
                                            timeIntervals={30}
                                            timeFormat="HH:mm"
                                            selected={endDate}
                                            onChange={handleEndChange}
                                        />
                                    </div> */}
                                    <div>
                                        <label>Start Date:</label>
                                        <input
                                            type="date"
                                            value={formatDate(startDate)}
                                            onChange={handleStartChange}
                                            className="datepick"
                                        />
                                    </div>
                                    <div>
                                        <label>End Date:</label>
                                        <input
                                            type="date"
                                            value={formatDate(endDate)}
                                            onChange={handleEndChange}
                                            className="datepick"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="row tab-content">
                                <div
                                    id="list-view"
                                    className="tab-pane fade active show col-lg-12"
                                >
                                    <div className="card">
                                        <div className="card-header">
                                            <DataTable
                                                title="Case Initiated Date and Notes"
                                                className="custom-data-table"
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                paginationTotalRows={data.length}
                                                onChangePage={handlePageChange}
                                                paginationPerPage={perPage}
                                                paginationRowsPerPageOptions={[10, 20, 30]} // Customize rows per page options
                                                onChangeRowsPerPage={handlePerPageChange}
                                                paginationPerPageLabel="Rows per page"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
