import React, { useState, useEffect } from 'react';
import "./login.css"
import utils from "../Components/utils"
import axios from 'axios';
import withReactContent from 'sweetalert2-react-content';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import LoginBg from "../Assets/Images/login-bg.svg";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Using FontAwesome for icons

export default function Login() {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [showPassword, setShowPassword] = useState(false);

    const checklogin = () => {
        let checksalestoken = localStorage.getItem("SalesToken");
        let checkfmtoken = localStorage.getItem("FilingManagerToken");
        let checkPstoken = localStorage.getItem("Pre_SalesToken");
        let checkFttoken = localStorage.getItem("FilingTeamToken");
        let checkinfluencerToken = localStorage.getItem("InfluencerToken");
        let checkAdmintoken = localStorage.getItem("Admintoken");
        if (
            checksalestoken != null ||
            checkfmtoken != null ||
            checkPstoken != null ||
            checkFttoken != null ||
            checkinfluencerToken != null ||
            checkAdmintoken != null
        ) {
            navigate('/dashboard');
        }
    };
    checklogin();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async (formData) => {
        const data = {
            email: formData.username,
            password: formData.password
        };
        console.log("data", data)
        const response = await axios.post(`${utils}/emplogin`, data)
        console.log("response", response)
        if (response.data.error == false) {
            MySwal.fire({
                icon: 'success',
                title: 'Success!',
                text: `${response.data.message}`,
            });
            Object.entries(response.data).forEach(([key, value]) => {
                if (key !== "error" && key !== "message") {
                    localStorage.setItem(key, value);
                    const token = value
                    const decodedPayload = atob(token.split('.')[1]);
                    const parsedPayload = JSON.parse(decodedPayload);
                    const userId = parsedPayload.userId;
                    localStorage.setItem('userId', userId);
                }
            });
            navigate('/dashboard')
        } else {
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: `${response.data.message}`,
            });
        }
    };

    return (
        <>
            <section className='login-section'>
                <div className="container">
                    <div className="row justify-content-evenly align-items-center">
                        <div className="col-12 mb-5">
                            <div className="main-logo text-center">
                                <img src="../Assets/Images/logo.png" alt="logo" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 mb-md-0 mb-4">
                            <div className="left-side-img">
                                <img src={LoginBg} alt="login-bg" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="right-side-login-content">
                                <div className="top-text">
                                    <h4>Sign In</h4>
                                    <form onSubmit={handleSubmit(handleLogin)}>
                                        <div className="mb-4">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder='Enter Email'
                                                autoComplete='off'
                                                {...register('username', { required: 'Email is required' })}
                                            />
                                            {errors.username && (
                                                <span className="text-danger">{errors.username.message}</span>
                                            )}
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <div className="form-group" style={{ position: 'relative' }}>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    className="form-control"
                                                    id="password"
                                                    placeholder='Enter Password'
                                                    autoComplete='off'
                                                    {...register('password', { required: 'Password is required' })}
                                                />
                                                <span
                                                    onClick={togglePasswordVisibility}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    {showPassword ? (
                                                        <i className="fas fa-eye-slash"></i>
                                                    ) : (
                                                        <i className="fas fa-eye"></i>
                                                    )}
                                                </span>
                                            </div>

                                            {errors.password && (
                                                <span className="text-danger">{errors.password.message}</span>
                                            )}
                                        </div>
                                        <div className="mb-4">
                                            <button type="submit" className="w-100 login-btn">Log In</button>
                                        </div>
                                        <div className="form-check d-flex flex-wrap align-items-center justify-content-between">
                                            <div>
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" defaultChecked />
                                                <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                                            </div>
                                            <span>
                                                <Link to={"/forgot-password"} className='forgot-link'>Forgot Password</Link>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
