import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from "axios";
import Swal from "sweetalert2";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import checklogin from "./checkLogin";

const LeadType = () => {
  const [data, setData] = useState([]);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [updatemodal, setUpdateModal] = useState(false);
  const toggle = () => {
    setModal(false);
    formik.resetForm();
  };
  const toggleOpen = () => setModal(true);
  const updatetogglecloase = () => setUpdateModal(!updatemodal);
  const [searchText, setSearchText] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  checklogin();

  const updatetoggle = (userId) => {
    axios
      .post(`${host}/listlead-typebyid`, { id: userId })
      .then((response) => {
        const userData = response.data;
        const initialValues = {
          id: userData.data?._id || "",
          lead_type: userData.data?.lead_type || "",
        };
        updateformik.setValues(initialValues);
        setUpdateModal(!updatemodal);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${host}/listlead-type`);
      if (response.data.error == false) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          id: id.trim(),
        };
        const response = await axios.post(`${host}/deletelead-typebyid`, data);
        if (response.data.error == false) {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: `${response.data.message}`,
          });
        }
      } else {
        MySwal.fire({
          icon: "warning",
          title: "warning!",
          text: `Your data successfully safe`,
        });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      lead_type: (userData?.lead_type || "").trim(),
    },

    validationSchema: Yup.object({
      lead_type: Yup.string().trim().required("Lead Type is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      axios.post(`${host}/addlead-type`, values, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setLoading(false);
          if (response.data.error == false) {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: `${response.data.message}`,
            });
            toggle();
            setTimeout(() => {
              fetchData();
            }, 1000);
          } else {
            MySwal.fire({
              icon: "warning",
              title: "warning!",
              text: `${response.data.message}`,
            });
          }
        })
        .catch((error) => {
          MySwal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while saving your data.",
          });
        });
    },
  });

  const updateformik = useFormik({
    initialValues: {
      id: userData?._id || "",
      lead_type: userData?.lead_type || "",
    },
    validationSchema: Yup.object({
      lead_type: Yup.string().required("Lead Type is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post(`${host}/updatelead-typebyid`, values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.error == false) {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: `${response.data.message}`,
            });
            updatetogglecloase();
            setTimeout(() => {
              fetchData();
            }, 1000);
          } else {
            MySwal.fire({
              icon: "warning",
              title: "warning!",
              text: `${response.data.message}`,
            });
          }
        })
        .catch((error) => {
          MySwal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while saving your data.",
          });
        });
    },
  });

  let paginatedData = [];
  if (Array.isArray(data)) {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    paginatedData = data.slice(startIndex, endIndex);
  } else {
    console.error("Data is not an array.");
  }

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * perPage,
      sortable: true,
      // width: '100px !important',
    },
    {
      name: "Lead Type",
      selector: (row) => (
        <span>{`${row.lead_type}`}</span>
      ),
      sortable: true,
      // width: "200px !important"
    },
    {
      name: "Action",
      selector: (row) => row.id,
      width: "100px !important",
      sortable: true,
      cell: (row) => (
        <div>
          {/* Update Icon */}
          <span
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => updatetoggle(row._id)}
          >
            <i className="fas fa-edit" title="Update"></i>
          </span>

          {/* Delete Icon */}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(row._id)}
          >
            <i className="fas fa-trash-alt" title="Delete"></i>
          </span>
        </div>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData =
    data && data.data
      ? data.data.filter((item) => {
        return (
          item.first_name != "" &&
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText.toLowerCase())
          )
        )
      }
      )
      : [];

  return (
    <>
      <Sidebar />
      <CustomNavbar />
      <div className="main-content">
        <div className="container-fluid">
          <div className="topbox">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="left-logo">
                  <div>
                    <img src={profile2} alt="Logo" className="logo" />
                  </div>
                  <p className="topboxttex">Lead Management</p>
                </div>
              </div>
              {/* <div className="right-logo"> */}
              <div className="col-md-4 ">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Leads
                    </li>
                  </ol>
                </nav>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 ">
              <div className="d-flex justify-content-between  me-2">
                <div className="wrap">
                  <div className="search">
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Search"
                      style={{ borderRadius: "7px", height: "35px" }}
                    />
                    {/* <button type="submit" className="searchButton">
                      Search
                    </button> */}
                  </div>
                </div>

                <button className="buttonAdd" onClick={toggleOpen}>
                  Add +
                </button>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="row tab-content">
                {/* <div
                  id="list-view"
                  className="tab-pane fade active show col-lg-12"
                > */}
                <div className="card">
                  <div className="card-header">
                    <DataTable
                      className="custom-data-table activity-data-table"
                      columns={columns}
                      data={filteredData}
                      pagination
                      paginationTotalRows={data.length}
                      onChangePage={handlePageChange}
                      paginationPerPage={perPage}
                      paginationRowsPerPageOptions={[10, 20, 30]} // Customize rows per page options
                      onChangeRowsPerPage={handlePerPageChange}
                      paginationPerPageLabel="Rows per page"
                    />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="right-aligned-modal myrightmodal"
        >
          <ModalHeader toggle={toggle}>Add</ModalHeader>
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label>Lead Type</Label>
                <Input
                  type="text"
                  name="lead_type"
                  id="lead_type"
                  placeholder="Enter Lead Type"
                  value={formik.values.lead_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.lead_type && formik.errors.lead_type ? (
                  <div className="error">{formik.errors.lead_type}</div>
                ) : null}
              </FormGroup>
              <ModalFooter>
                {loading ? (
                  <Button
                    style={{
                      background:
                        "linear-gradient(175deg, #1B0B54 0%, #090221 100%)",
                      color: "#fff",
                      borderRadius: "50px",
                    }}
                  >
                    Loading....
                  </Button>
                ) : (
                  <Button
                    style={{
                      background:
                        "linear-gradient(175deg, #1B0B54 0%, #090221 100%)",
                      color: "#fff",
                      borderRadius: "50px",
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                )}
                <Button color="secondary" type="reset" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={updatemodal}
          toggle={updatetoggle}
          className="right-aligned-modal"
        >
          <ModalHeader toggle={updatetogglecloase}>Update</ModalHeader>
          <ModalBody>
            <form onSubmit={updateformik.handleSubmit}>
              <FormGroup>
                <Label>Lead Type</Label>
                <Input
                  type="text"
                  name="lead_type"
                  id="lead_type"
                  placeholder="Enter Name"
                  value={updateformik.values.lead_type}
                  onChange={updateformik.handleChange}
                  onBlur={updateformik.handleBlur}
                />
                {updateformik.touched.lead_type &&
                  updateformik.errors.lead_type ? (
                  <div className="error">{updateformik.errors.lead_type}</div>
                ) : null}
              </FormGroup>

              <ModalFooter>
                {loading ? (
                  <Button
                    style={{
                      background:
                        "linear-gradient(175deg, #1B0B54 0%, #090221 100%)",
                      color: "#fff",
                      borderRadius: "50px",
                    }}
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    style={{
                      background:
                        "linear-gradient(175deg, #1B0B54 0%, #090221 100%)",
                      color: "#fff",
                      borderRadius: "50px",
                    }}
                    type="submit"
                  >
                    Update
                  </Button>
                )}
                <Button color="secondary" onClick={updatetogglecloase}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default LeadType;
