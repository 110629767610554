import React from 'react';

const ThankYou = () => {
    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Thank You For Accepting the Terms & Conditions!</h2>
            <p style={styles.message}>Your acceptance is greatly appreciated.</p>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9', // Light gray background
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        color: '#007bff', // Blue color
    },
    message: {
        fontSize: '18px',
        marginBottom: '20px',
    },
};

export default ThankYou;
