import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import withReactContent from "sweetalert2-react-content";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import Swal from "sweetalert2";
import host from "./utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Influencer.css";

const Influencer = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      contact_number: "",
      alternate_number: "",
    },
    mode: "onChange",
  });
  const [defaultCountryNumber, setDefaultCountryNumber] = useState("in");
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cancelledChequeFile, setCancelledChequeFile] = useState(null);
  const [idProofFile, setIdProofFile] = useState(null);

  const MySwal = withReactContent(Swal);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [filePreview, setFilePreview] = useState(null);
  const [fileDownloadUrl, setFileDownloadUrl] = useState(null);
  const [numberError, setNumberError] = useState({
    contact_number_error: false,
    alternate_number_error: false,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleCancelledCheque(event);

    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setFilePreview(previewUrl);
      setFileDownloadUrl(previewUrl);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword({ ...showPassword, password: !showPassword?.password });
  };

  const togglePasswordConfirmVisibility = () => {
    setShowPassword({
      ...showPassword,
      confirmPassword: !showPassword?.confirmPassword,
    });
  };

  const selectedCountry = watch("country");
  const firstName = watch("first_name");
  const lastName = watch("last_name");
  const country = watch("country");
  const address = watch("address");
  const email = watch("email");
  const phoneNumber = watch("contact_number");
  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const countries = [
    { name: "India", short_name: "IN" },
    { name: "Canada", short_name: "CA" },
    // Add more countries...
  ];

  const genders = [{ name: "Male" }, { name: "Female" }];

  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    if (e?.target?.name) {
      const nameKey = e.target.name;
      const namesMapping = {
        contact_number: "contact_number_error",
        alternate_number: "alternate_number_error",
      };
      setNumberError((prev) => ({
        ...prev,
        [namesMapping[nameKey]]: false,
      }));
      setValue(e.target.name, value || "", { shouldValidate: true });
      // trigger(e.target.name);
    }

    if (country?.countryCode) {
      // setDefaultCountryNumber(country.countryCode);
    }
  };

  const handleIdProof = (event) => {
    setValue("id_prrof", event.target.files);
  };
  const isPhoneNumberValid = (number) => {
    return (
      number &&
      typeof number === "string" &&
      number.startsWith("+") &&
      number.replace(/\D/g, "").length >= 10
    );
  };

  const handleCancelledCheque = (event) => {
    setValue("cancelled_cheque", event.target.files);
  };

  const nextStep = async () => {
    let stepValid = false;

    // if (step === 1) {
    //   stepValid = await trigger([
    //     "first_name",
    //     "last_name",
    //     "email",
    //     "contact_number",
    //     "password",
    //     "conpassword",
    //     "address",
    //     "gender",
    //     "social_media_name",
    //     "social_media_link",
    //   ]);

    //   let contact_number = getValues("contact_number");
    //   let alternate_number = getValues("alternate_number");

    //   const isContactNumberValid = contact_number;
    //   const isAlternateNumberValid = alternate_number;

    //   if (!isContactNumberValid || !isAlternateNumberValid) {
    //     setNumberError({
    //       contact_number_error: !isContactNumberValid,
    //       alternate_number_error: !isAlternateNumberValid,
    //     });
    //     stepValid = false;
    //   }
    // }

    if (step === 1) {
      stepValid = await trigger([
        "first_name",
        "last_name",
        "email",
        "contact_number",
        "password",
        "conpassword",
        "address",
        "gender",
        "social_media_name",
        "social_media_link",
      ]);
      let contact_number = getValues("contact_number");
      let alternate_number = getValues("alternate_number");

      if (
        contact_number === "" ||
        contact_number === undefined ||
        alternate_number === "" ||
        alternate_number === undefined
      ) {
        setNumberError({
          contact_number_error: true,
          alternate_number_error: true,
        });
        stepValid = false;
      } else {
        setNumberError({
          contact_number_error: false,
          alternate_number_error: false,
        });
      }
    }

    if (step === 2) {
      stepValid = await trigger([
        "country",
        "bank_name",
        "account_number",
        "ifsc_code",
        "name_of_person",
        "institution_number",
        "transit_no",
        "swift_code",
        "cancelled_cheque",
      ]);
    }

    if (step === 3) {
      stepValid = await trigger(["proof_photo", "id_prrof"]);
    }

    if (step === 4) {
      // Validate all fields on the final step
      stepValid = await trigger(["agreement"]); // Include other fields if any
    }

    if (stepValid) {
      if (step === 4) {
        // Final form submission or any other action for the last step
        handleSubmit(onSubmit)(); // Make sure you have a handleSubmit and onSubmit defined
      } else {
        setStep(step + 1); // Move to the next step
      }
    }
  };

  // Go to the previous step
  const prevStep = () => setStep(step - 1);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    // Append regular form fields
    Object.entries(data).forEach(([key, value]) => {
      // Make sure to only append files and handle them appropriately
      if (key === "cancelled_cheque" || key === "id_prrof") {
        // Append the file object
        formData.append(key, value[0]); // value is an array of files
      } else {
        // Append regular string fields
        formData.append(key, value);
      }
    });

    try {
      const response = await axios.post(`${host}/influencer-addrole`, formData);
      setLoading(false);

      if (response.data.error) {
        MySwal.fire({
          icon: "warning",
          title: "Warning!",
          text: `${response.data.message}`,
        });
      } else {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: `${response.data.message}`,
        });
        reset(); // Reset the form
        setStep(1); // Reset to the first step
      }
    } catch (error) {
      setLoading(false);
      MySwal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while adding the lead. Please try again.",
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="sub-form-cls">
        <div
          className="modal-body modalpadding"
          style={{
            width: "100%",
            maxWidth: "600px",
            margin: "auto",
            backgroundColor: "#fff",
            padding: "40px",
            borderRadius: "12px",
            boxShadow: "0 8px 30px rgba(0, 0, 0, 0.12)",
          }}
        >
          <div className="text-center mb-4">
            <img
              src="../Assets/Images/logo.png" // Path to your logo image
              alt="Logo"
              style={{ width: "200px", height: "auto" }}
            />
          </div>

          <div
            className="row"
            style={{
              backgroundColor: "#f7f7f7",
              padding: "25px",
              borderRadius: "8px",
              border: "1px solid #ececec",
            }}
          >
            {/* Step 1: Personal Information */}
            {step === 1 && (
              <>
                <div className="col-md-12 mb-2 bg-color">
                  {/* Personal Information */}
                </div>
                <div className="form-group col-md-6">
                  <label className="required">First Name</label>
                  <br />
                  <input
                    type="text"
                    placeholder="First name"
                    className="form-control"
                    name="first_name"
                    {...register("first_name", {
                      required: "Please enter first name",
                    })}
                  />
                  {errors.first_name && (
                    <div className="error-message">
                      {errors.first_name.message}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="required">Last Name</label>
                  <br />
                  <input
                    type="text"
                    placeholder="last name"
                    className="form-control"
                    name="last_name"
                    {...register("last_name", {
                      required: "Please enter last name",
                    })}
                  />
                  {errors.last_name && (
                    <div className="error-message">
                      {errors.last_name.message}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="required">Email </label>
                  <br />
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    className="form-control"
                    {...register("email", {
                      required: "Please enter the email",
                    })}
                  />
                  {errors.email && (
                    <div className="error-message">{errors.email.message}</div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="required">Contact Number</label>
                  <br />
                  <PhoneInput
                    disableInitialCountryGuess={false}
                    disableCountryGuess={true}
                    country={defaultCountryNumber}
                    value={getValues("contact_number")}
                    onChange={handlePhoneInputChange}
                    inputProps={{
                      name: "contact_number",
                      onBlur: () => {
                        const currentValue = getValues("contact_number");
                        if (currentValue !== "") {
                          setNumberError((prev) => ({
                            ...prev,
                            contact_number_error: false,
                          }));
                        }
                      },
                      className: `form-control form-input-number ${
                        errors.contact_number ? "is-invalid" : ""
                      }`,
                    }}
                  />
                  {numberError.contact_number_error && (
                    <div className="error-message">
                      Please enter the contact number
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label>Alternate Number</label>
                  {/* <label>Alternate Number *</label> */}
                  <br />
                  <PhoneInput
                    disableInitialCountryGuess={false}
                    disableCountryGuess={true}
                    country={defaultCountryNumber}
                    onChange={handlePhoneInputChange}
                    value={getValues("alternate_number")}
                    inputProps={{
                      name: "alternate_number",
                      onBlur: () => {
                        const currentValue = getValues("alternate_number");
                        if (currentValue !== "") {
                          setNumberError((prev) => ({
                            ...prev,
                            alternate_number_error: false,
                          }));
                        }
                      }, // onBlur registration
                      className: `form-control form-input-number ${
                        errors.alternate_number ? "is-invalid" : ""
                      }`,
                    }}
                  />
                  {numberError.alternate_number_error && (
                    <div className="error-message">
                      Please enter the alternate number
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="required">Password </label>
                  <br />

                  <div className="password-container">
                    <input
                      type={showPassword?.password ? "text" : "password"}
                      placeholder="Enter Password"
                      name="password"
                      className="form-control"
                      {...register("password", {
                        required: "Please enter the password",
                      })}
                    />
                    <span
                      className="password-toggle-icon"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword?.password ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {errors.password && (
                    <div className="error-message">
                      {errors.password.message}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="required">Confim Password</label>
                  <br />
                  <div className="password-container">
                    <input
                      type={showPassword?.confirmPassword ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      name="conpassword"
                      className="form-control"
                      {...register("conpassword", {
                        required: "Please enter the password",
                      })}
                    />
                    <span
                      className="password-toggle-icon"
                      onClick={togglePasswordConfirmVisibility}
                    >
                      {showPassword?.confirmPassword ? (
                        <FaEyeSlash />
                      ) : (
                        <FaEye />
                      )}
                    </span>
                  </div>

                  {errors.conpassword && (
                    <div className="error-message">
                      {errors.conpassword.message}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="required">Address</label>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Address"
                    name="address"
                    className="form-control"
                    {...register("address", {
                      required: "Please enter the Address",
                    })}
                  />
                  {errors.address && (
                    <div className="error-message">
                      {errors.address.message}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="required">Gender</label>
                  <br />
                  <select
                    className="form-control"
                    aria-label="Default select example"
                    name="gender"
                    {...register("gender", {
                      required: "Please select the gender",
                    })}
                  >
                    <option value="">Select</option>
                    {genders.map((gender) => (
                      <option value={gender.name} key={gender.name}>
                        {gender.name}
                      </option>
                    ))}
                  </select>
                  {errors.gender && (
                    <div className="error-message">{errors.gender.message}</div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label>Social Media Channel Name</label>
                  {/* <label>Social Media Channel Name *</label> */}
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Social Media Channel Name"
                    name="social_media_name"
                    className="form-control"
                    {...register("social_media_name", {})}
                  />
                  {errors.social_media_name && (
                    <div className="error-message">
                      {errors.social_media_name.message}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  {/* <label>Social Media Link *</label> */}
                  <label>Social Media Link</label>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Social Media Link"
                    name="social_media_link"
                    className="form-control"
                    {...register("social_media_link", {})}
                  />
                  {errors.social_media_link && (
                    <div className="error-message">
                      {errors.social_media_link.message}
                    </div>
                  )}
                </div>

                <input
                  type="hidden"
                  placeholder="Enter Job Role"
                  name="job_role"
                  // value="CCS-partner"
                  value="Influencer"
                  className="form-control"
                  {...register("job_role", {
                    required: "Please enter the job role",
                  })}
                />
              </>
            )}

            {/* Step 2: Bank Information */}
            {step === 2 && (
              <>
                <div className="col-md-12 mb-2 bg-color">Bank Information</div>

                <div className="form-group col-md-6">
                  <label className="required">Country Of Bank</label>
                  <br />
                  <select
                    className="form-control"
                    aria-label="Default select example"
                    name="country"
                    {...register("country", {
                      required: "Please select the country",
                    })}
                  >
                    <option value="">Select</option>
                    {countries.map((country) => (
                      <option
                        value={country.short_name}
                        key={country.short_name}
                      >
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <div className="error-message">
                      {errors.country.message}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="required">Bank Name</label>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Bank Name"
                    className="form-control"
                    name="bank_name"
                    {...register("bank_name", {
                      required: "Please enter the bank name",
                    })}
                  />
                  {errors.bank_name && (
                    <div className="error-message">
                      {errors.bank_name.message}
                    </div>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="required">Account Number</label>
                  <br />
                  <input
                    type="text"
                    placeholder="Enter Account Number"
                    className="form-control"
                    name="account_number"
                    {...register("account_number", {
                      required: "Please enter the account number",
                    })}
                  />
                  {errors.account_number && (
                    <div className="error-message">
                      {errors.account_number.message}
                    </div>
                  )}
                </div>

                {/* Conditionally render the State field for India */}
                {selectedCountry === "IN" && (
                  <>
                    <div className="form-group col-md-6">
                      <label className="required">IFSC Code</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter IFSC Code"
                        className="form-control"
                        name="ifsc_code"
                        {...register("ifsc_code", {
                          required: "Please enter the IFSC code",
                        })}
                      />
                      {errors.ifsc_code && (
                        <div className="error-message">
                          {errors.ifsc_code.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="required">Name Of Person</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Name Of Person"
                        className="form-control"
                        name="name_of_person"
                        {...register("name_of_person", {
                          required: "Please enter the name of person",
                        })}
                      />
                      {errors.name_of_person && (
                        <div className="error-message">
                          {errors.name_of_person.message}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* Conditionally render the City field for Canada */}
                {selectedCountry === "CA" && (
                  <>
                    <div className="form-group col-md-6">
                      <label className="required">Institution Number</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Institution Number"
                        className="form-control"
                        name="institution_number"
                        {...register("institution_number", {
                          required: "Please enter the institution number",
                        })}
                      />
                      {errors.institution_number && (
                        <div className="error-message">
                          {errors.institution_number.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="required">Transit No.</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Transit No."
                        className="form-control"
                        name="transit_no"
                        {...register("transit_no", {
                          required: "Please enter the transit no",
                        })}
                      />
                      {errors.transit_no && (
                        <div className="error-message">
                          {errors.transit_no.message}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="required">Swift Code</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Swift Code"
                        className="form-control"
                        name="swift_code"
                        {...register("swift_code", {
                          required: "Please enter the swift code",
                        })}
                      />
                      {errors.swift_code && (
                        <div className="error-message">
                          {errors.swift_code.message}
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="form-group col-md-6">
                  <label className="required">
                    Upload Copy Of Cancelled Cheque
                  </label>
                  <br />
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    className="form-control"
                    name="cancelled_cheque"
                    onChange={handleCancelledCheque}
                    {...register("cancelled_cheque", {
                      required: "Please upload a copy of your cancelled cheque",
                    })}
                  />
                  {errors.cancelled_cheque && (
                    <div className="error-message">
                      {errors.cancelled_cheque.message}
                    </div>
                  )}
                </div>
              </>
            )}

            {/* Step 3: Id Proof */}
            {step === 3 && (
              <>
                <div className="col-md-12 mb-2 bg-color">Id Proof</div>

                <div className="form-group col-md-6">
                  <label className="required">
                    Upload Copy Of Aadhar Card / Passport
                  </label>
                  <br />
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    className="form-control"
                    name="id_prrof"
                    onChange={handleIdProof}
                    {...register("id_prrof", {
                      required: "Please upload a ID Proof",
                    })}
                  />
                  {errors.id_prrof && (
                    <div className="error-message">
                      {errors.id_prrof.message}
                    </div>
                  )}
                </div>
              </>
            )}

            {/* Step 4: Agreement */}
            {step === 4 && (
              <>
                <div className="col-md-12 mb-2 bg-color">Agreement T&C</div>

                <div className="form-check">
                  <label className="form-check-label" htmlFor="agreement">
                    <strong>
                      Check Check services Partner Program Terms and Conditions
                    </strong>
                    {/* <strong>Check Check services Influencer Partner Program Terms and Conditions</strong> */}
                    <br />
                    <strong>Effective Date:</strong> {currentDate}
                    <br />
                    <br />
                    Welcome to Check Check Services Partner Program! By
                    participating in our Partner Program, you agree to the
                    following Terms and Conditions ("Terms"). Please read them
                    carefully.
                    {/* Welcome to Check Check Services Influencer Partner Program! By participating in our Partner Program, you agree to the following Terms and Conditions ("Terms"). Please read them carefully. */}
                    <br />
                    <br />
                    <strong>1. Enrollment</strong>
                    <br />
                    <strong>1.1 Eligibility:</strong> To enroll in the Partner
                    Program, you must have a social media account/ channel with
                    active subscribers.
                    <br />
                    <strong>1.2 Application:</strong> You must submit a
                    completed application form to join the Partner Program. We
                    reserve the right to accept or reject any application at our
                    sole discretion.
                    <br />
                    <strong>1.3 Account Approval:</strong> Upon approval, you
                    will receive a Partner Account and access to our partner
                    portal.
                    <br />
                    <br />
                    <strong>2. Partner Responsibilities</strong>
                    <br />
                    <strong>2.1 Promotion:</strong> Partners are responsible for
                    promoting Check Check Services products and services in
                    accordance with the marketing materials and guidelines
                    provided.
                    <br />
                    <strong>2.2 Compliance:</strong> You agree to comply with
                    all applicable laws and regulations while participating in
                    the Partner Program.
                    <br />
                    <br />
                    <strong>3. Compensation</strong>
                    <br />
                    <strong>3.1 Commission Structure:</strong> Partners will
                    receive compensation based on a fixed payout.
                    <br />
                    <strong>3.2 Payment Terms:</strong> Payments will be made
                    within 7 days after submission of file.
                    <br />
                    <br />
                    <strong>4. Intellectual Property</strong>
                    <br />
                    <strong>4.1 License:</strong> Check Check Services grants
                    you a non-exclusive, non-transferable license to use its
                    trademarks, logos, and marketing materials solely for the
                    purpose of promoting its products and services.
                    <br />
                    <strong>4.2 Restrictions:</strong> You may not alter or
                    misuse the company's intellectual property or create
                    derivative works based on it.
                    <br />
                    <br />
                    <strong>5. Confidentiality</strong>
                    <br />
                    <strong>5.1 Confidential Information:</strong> You agree to
                    keep confidential any non-public information provided by
                    Check Check Services and to use it only for the purpose of
                    participating in the Partner Program.
                    <br />
                    <strong>5.2 Disclosure:</strong> You may only disclose
                    confidential information if required by law or with prior
                    written consent from Check Check Services.
                    <br />
                    <br />
                    <strong>6. Termination</strong>
                    <br />
                    <strong>6.1 Termination for Convenience:</strong> Either
                    party may terminate the contract at any time with 30 days
                    written notice.
                    <br />
                    <strong>6.2 Termination for Cause:</strong> Check Check
                    Services. may terminate the partnership immediately if you
                    breach any terms of this agreement or engage in unethical or
                    illegal conduct.
                    <br />
                    <strong>6.3 Effects of Termination:</strong> Upon
                    termination, you must cease all use of Check Check Services
                    intellectual property and return or destroy any confidential
                    information.
                    <br />
                    <br />
                    <strong>7. Limitation of Liability</strong>
                    <br />
                    <strong>7.1 Disclaimer:</strong> Check Check Services makes
                    no warranties or representations regarding the Partner
                    Program, and it is provided "as is."
                    <br />
                    <strong>7.2 Limitation of Liability:</strong> To the maximum
                    extent permitted by law, Check Check Services will not be
                    liable for any indirect, incidental, or consequential
                    damages arising from your participation in the Partner
                    Program.
                    <br />
                    <br />
                    <strong>8. Governing Law</strong>
                    <br />
                    These Terms will be governed by and construed in accordance
                    with the laws of {country == "IN" ? "India" : "Canada"},
                    without regard to its conflict of law principles.
                    <br />
                    <br />
                    <strong>9. Dispute Resolution</strong>
                    <br />
                    Any disputes arising out of or related to these Terms will
                    be resolved through [describe dispute resolution process,
                    e.g., arbitration, mediation] in{" "}
                    {country == "IN" ? "India" : "Canada"}.
                    <br />
                    <br />
                    <strong>10. Amendments</strong>
                    <br />
                    Check Check Services reserves the right to modify these
                    Terms at any time. Any changes will be effective immediately
                    by notifying you directly.
                    <br />
                    <br />
                    <strong>11. Contact Information</strong>
                    <br />
                    For any questions or concerns about these Terms, please
                    contact us at:
                    <br />
                    <br />
                    Check Check Services
                    <br />
                    222 McIntyre St W Suite 305, North Bay, ON P1B 2Y8
                    <br />
                    apply@studybuddycanada.com
                    <br />
                    +1-514-726-0003
                    <br />
                    <br />
                    By participating in the Partner Program, you acknowledge
                    that you have read, understood, and agreed to these Terms
                    and Conditions.
                  </label>
                  <div className="mt-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="agreement"
                      {...register("agreement", {
                        required: "You must accept the agreement",
                      })}
                    />
                    <label className="form-check-label" htmlFor="agreement">
                      I accept the terms of the agreement T&C.
                    </label>
                    {errors.agreement && (
                      <div className="error-message">
                        {errors.agreement.message}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="text-center">
            {step > 1 && (
              <button
                type="button"
                className="btn btn-secondary me-2"
                onClick={prevStep}
              >
                Back
              </button>
            )}
            {step < 4 && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={nextStep}
              >
                Next
              </button>
            )}
            {step === 4 &&
              (loading ? (
                <button className="btn btn-primary me-2" disabled>
                  Loading...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary me-2">
                  Save
                </button>
              ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Influencer;