import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import checklogin from "./checkLogin";
import { useForm } from 'react-hook-form';
import Offcanvas from "react-bootstrap/Offcanvas";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomNavbar from "../directives/navbar";
import Sidebar from "../directives/barside";
import host from "./utils";
import profile2 from "../Assets/Images/topboxlogo.png";
import infomationLoginUser from "./LoginUSerInfo";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import OffCanvasExample from "./Offconvas";

export default function SaleAdminManage({ paginationTotalRows, onPageChange, perPage, onPerPageChange, paginationRowsPerPageOptions, paginationPerPageLabel }) {
  checklogin();
  infomationLoginUser();
  const [data, setData] = useState([]);
  const MySwal = withReactContent(Swal);
  const [searchText, setSearchText] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [salesData, setSalesData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [followup, setFollowup] = useState(false);
  const [caseInitiatedChecked, setCaseInitiatedChecked] = useState(false);
  const [fileInitiatedChecked, setFileIntiatedChecked] = useState(false);
  const [docReceivedChecked, setDocsReceivedChecked] = useState(false);
  const [soppreprationChecked, setSoppreprationChecked] = useState(false);
  const [sopLetterChecked, setSopLettersChecked] = useState(false);
  const [confirmReceivedChecked, setConfirmRecievedChecked] = useState(false);
  const [fileSentChecked, setFilesentChecked] = useState(false);
  const [visaApprovedChecked, setVisaapprovedChecked] = useState(false);
  const [visaRefusalChecked, setVisaRefusalChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(perPage || 10);
  const [show, setShow] = useState(false);
  const [id, editId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [leadTypeList, setLeadTypeList] = useState([]);
  const [leadSourceList, setLeadSourceList] = useState([]);
  const [serviceName, setServiceName] = useState([]);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, watch, formState: { errors }, setValue, reset, } = useForm();
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const tableCustomStyles = {
    headCells: {
      style: {
        width: "100%",
        // fontSize: "18px",
        // fontWeight: "bold",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        width: "100%",
        // fontSize: "18px",
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  useEffect(() => {
    fetchData();
    handleAssignLeadClick();
    fetchCountries();
    fetchLeadType();
    fetchLeadSource();
    fetchServiceName();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, searchText, startDate, endDate, totalRows]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${host}/list-allleads`, {
        params: {
          page: currentPage,
          perPage: itemsPerPage,
          searchQuery: searchText,
          startDate: startDate,
          endDate: endDate,
          status: 2
        }
      });
      if (response.data.success == true) {
        setTotalRows(response.data.totalRows);
        setData(response.data.data);
        setFilteredData(filterData(response.data.data));
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${host}/list-country`);
      if (response.data.error == false) {
        setCountries(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchLeadType = async () => {
    try {
      const response = await axios.get(`${host}/listlead-type`);
      if (response.data.error == false) {
        setLeadTypeList(response.data);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchLeadSource = async () => {
    try {
      const response = await axios.get(`${host}/listlead-source`);
      if (response.data.error == false) {
        setLeadSourceList(response.data);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchServiceName = async () => {
    try {
      const response = await axios.get(`${host}/listservice-name`);
      if (response.data.error == false) {
        setServiceName(response.data);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const handleDelete = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          id: id,
        };
        const response = await axios.post(`${host}/DeleteLead`, data);
        if (response.data.error == false) {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          MySwal.fire({
            icon: "warning",
            title: "warning!",
            text: `${response.data.message}`,
          });
        }
      } else {
        MySwal.fire({
          icon: "warning",
          title: "warning!",
          text: `Your data successfully safe`,
        });
      }
    });
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const [id, name] = selectedValue.split(":");
    setSelectedId(id);
    setSelectedName(name);
  };

  let paginatedData = [];
  if (Array.isArray(data)) {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    paginatedData = data.slice(startIndex, endIndex);
  } else {
    console.error("Data is not an array.");
  }

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * itemsPerPage,
      sortable: true,
      width: "100px !important"
    },
    {
      name: "Full Name",
      selector: (row) => (
        <div onClick={() => userFollowupShow(row._id)} style={{ cursor: "pointer" }}>
          <span className="text-info">{`${row.first_name} ${row.last_name || ""}`}</span>
        </div>
      ),
      sortable: true,
      width: "200px !important"
    },
    {
      name: "Status",
      selector: (row) => (
        <div>
          {row.status_convert[row.status_convert.length - 1] == "Close" ? (
            <button className="btn btn-danger">Close</button>
          ) : row.status_convert[row.status_convert.length - 1] == "Convert" ? (
            <button className="btn btn-success">Convert</button>
          ) : row.status_convert[row.status_convert.length - 1] == "Pending" ? (
            "Pending"
          ) : (
            ""
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "250px !important"
    },
    {
      name: "Contact no.",
      selector: (row) => row.contact_number,
      sortable: true,
    },
    {
      name: "Country.",
      selector: (row) => row.country_name,

      sortable: true,
    },
    {
      name: "Sales Executive.",
      selector: (row) => row.Lead_AssignName,
      sortable: true,
    },
    {
      name: "Lead Type.",
      selector: (row) => row.Lead_Type,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      width: '100px !important',
    },
    {
      name: "Alternate Contact No.",
      selector: (row) => row.alt_contact_number,
      sortable: true,
    },
    {
      name: "Age.",
      selector: (row) => row.age == "undefined" ? "N/A" : row.age,
      sortable: true,
      width: "100px !important"
    },
    {
      name: "State.",
      selector: (row) => row.state_name,
      sortable: true,
    },
    {
      name: "Education.",
      selector: (row) => row.education,
      sortable: true,
    },
    {
      name: "Notes.",
      selector: (row) => row.note,
      sortable: true,
      width: "350px !important",
    },
    {
      name: "Lead Source.",
      selector: (row) => row.Lead_Source,
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      width: '100px !important',
      cell: (row) => {
        return (
          < div >
            <span
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => leadGetById(row._id)}
            >
              <i className="fas fa-edit" title="Update"></i>
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row._id)}
            >
              <i className="fas fa-trash-alt" title="Delete"></i>
            </span>
          </div >
        );
      },
    },
  ];

  const handleClose = () => {
    reset();
    setShow(false);
    setStates([]);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    if (id == '') {
      delete data._id;
      const formData = new FormData();
      formData.append('pre_sales_id', localStorage.getItem("userId"));
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      try {
        const response = await axios.post(`${host}/AddLead`, formData)
        setLoading(false)
        if (response.data.error == true) {
          MySwal.fire({
            icon: "warning",
            title: "Warning!",
            text: `${response.data.message}`,
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          handleClose();
          fetchData();
        }
      } catch (error) {
        setLoading(false);
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while adding the lead. Please try again.",
        });
      }
    } else {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      try {
        const response = await axios.post(`${host}/lead-updatebyid`, formData)
        setLoading(false);
        if (response.data.error == true) {
          MySwal.fire({
            icon: "warning",
            title: "Warning!",
            text: `${response.data.message}`,
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          handleClose();
          fetchData();
        }
      } catch (error) {
        setLoading(false);
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: "An error occurred while adding the lead. Please try again.",
        });
      }
    }
  };

  const handleDOBChange = (e) => {
    const dob = e.target.value;
    setValue("DOB", dob);
    const age = calculateAge(dob);
    setValue("age", age);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    setValue('contact_number', formattedValue || value);
  };

  const handleAlternatePhoneInputChange = (value, country, e, formattedValue) => {
    setValue('alt_contact_number', formattedValue || value);
  };

  const handleFileChange = (event) => {
    setValue('Upload_File', event.target.files[0]);
  };

  const getState = async (event, state) => {
    const data = {
      country_code: event
    }
    const response = await axios.post(`${host}/find-countrycode`, data);
    if (response.data.error == false) {
      if (state == '') {
        setStates(response.data.data);
      } else {
        response.data.data.forEach((val) => {
          if (state == val.short_name) {
            setValue('state', val.short_name)
          }
        })
        setStates(response.data.data);
      }
    }
  };

  const leadGetById = async (id) => {
    try {
      const response = await axios.post(`${host}/GetByIdLead`, {
        id: id,
      });
      setApiResponse(response.data);
      editId(response.data._id);
      setShow(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset current page when changing items per page
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const userFollowupShow = async (id) => {
    try {
      const response = await axios.post(`${host}/GetByIdLead`, {
        id: id,
      });
      setApiResponse(response.data);
      if (response.data.data.Filing_Process.length > 0) {
        for (let i = response.data.data.Filing_Process.length - 1; i >= 0; i--) {
          const val = response.data.data.Filing_Process[i];
          if (val.caseInitiated == 'Case Initiated') {
            setCaseInitiatedChecked(true);
          }
          if (val.caseInitiated == "true" && val.fileIntiated == 'File Initiated or docs checklist sent') {
            setFileIntiatedChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'Docs received & Pending docs sent') {
            setDocsReceivedChecked(true);
          }
          if (val.caseInitiated == "true" && val.fileIntiated == "true" && val.docsReceived == "true" && val.sopprepration == 'Sop Or letters prepration & Forms prep') {
            setSoppreprationChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'SOP or Letters sent to client') {
            setSopLettersChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'Confirmation received on SOP, letters and docs, Forms confirmation') {
            setConfirmRecievedChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'File sent for Upload or submission') {
            setFilesentChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'Visa approved') {
            setVisaapprovedChecked(true);
          }
          if (val.caseInitiated == 'true' && val.fileIntiated == 'true' && val.docsReceived == 'true' && val.sopprepration == 'true' && val.sopletters == 'true' && val.confirmrecieved == 'true' && val.filesent == 'true' && val.visaapproved == 'true' && val.visarefusal == 'Visa refusal') {
            setVisaRefusalChecked(true);
          }
        }
      } else {
        console.log("Not find any filling proccess");
      }
    } catch (error) {
      console.error(error);
    }
    setFollowup(true)
  }

  const followupClose = () => {
    setFollowup(false);
    setCaseInitiatedChecked(false);
    setFileIntiatedChecked(false);
    setDocsReceivedChecked(false);
    setSoppreprationChecked(false);
    setSopLettersChecked(false);
    setConfirmRecievedChecked(false);
    setFilesentChecked(false);
    setVisaapprovedChecked(false);
    setVisaRefusalChecked(false);
  }

  if (apiResponse && apiResponse.data) {
    var followUpStatus = apiResponse.data
    const followUpDates = apiResponse.data.Followup_Date_fiiling_team;
    var followupdatepayment = apiResponse.data.Followup_Date

    const NextFollowupDate = apiResponse.data.Next_Followup_Date_fiiling_team;
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };

    // Format regular follow-up dates
    var formattedFollowUpDates = followUpDates.map(dateString => {
      if (dateString) {
        const followUpDate = new Date(dateString);
        return followUpDate.toLocaleDateString('en-US', options);
      } else {
        return null;
      }
    });
    var followupdatepaymentData = followupdatepayment.map(dateStringpayment => {
      if (dateStringpayment) {
        const followUpDatepayment = new Date(dateStringpayment);
        return followUpDatepayment.toLocaleDateString('en-US', options);
      } else {
        return null;
      }
    });
    // Format Next Follow-up Date
    var formattedNextFollowupDate = NextFollowupDate.map(dateStringnext => {
      if (dateStringnext) {
        const followUpDateNext = new Date(dateStringnext);
        return followUpDateNext.toLocaleDateString('en-US', options);
      } else {
        return null;
      }
    });
    var notes = apiResponse.data.Notes_fiiling_team
    var TotalAmount = apiResponse.data.Total_Amount
  }

  useEffect(() => {
    if (apiResponse && apiResponse.data) {
      Object.keys(apiResponse.data).forEach((key) => {
        setValue(key, apiResponse.data[key]);
        getState(apiResponse.data.country, apiResponse.data.state);
      });
    }
  }, [apiResponse, setValue]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filterData =
    data && data.data
      ? data.data.filter((row) => {
        return (
          Object.values(row).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText.toLowerCase())
          )
        );
      })
      : [];

  const handleAssignLeadClick = () => {
    axios
      .get(`${host}/GetAllFillinfManager`)
      .then((response) => {
        setSalesData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleButtonClick = () => {
    if (selectedLeadIds.length === 0 || !selectedId) {
      MySwal.fire({
        icon: "warning",
        title: "Warning!",
        text: "Please select both a lead and a role before submitting.",
      });
      return; // Stop further execution if conditions are not met
    }
    const data = {
      id: selectedLeadIds,
      status: "3",
      Filing_ManagerID: selectedId,
      Filing_ManagerName: selectedName,
    };

    axios
      .post(`${host}/fillingmanager-leadassign`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.error == true) {
          MySwal.fire({
            icon: "warning",
            title: "Warning!",
            text: `${response.data.message}`,
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: `${response.data.message}`,
          });
          fetchData();
          setSelectedLeadIds([]);
          // window.location.href = 'http://192.168.29.47:3001/Adminsalemanage';
          // window.location = "https://checkcheckservices.in/Adminsalemanage";
        }
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: "Error!",
          text: `${error.message}`,
        });
      });
  };

  const formatLeadDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
  };

  const formatLeadTime = (timeString) => {
    const time = new Date(timeString);
    const hours = String(time.getHours()).padStart(2, '0');
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  const proficiencyTestValue = watch('Proficiency_Test');

  return (
    <>
      <Sidebar />
      <CustomNavbar />
      <div className="main-content">
        <div className="container-fluid">
          <div className="topbox">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <div className="left-logo">
                    <div>
                      <img src={profile2} alt="Logo" className="logo" />
                    </div>
                    <p className="topboxttex">Lead Management</p>
                  </div>
                </div>
                {/* <div className="right-logo"> */}
                <div className="col-md-4 ">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="dashboard">Dashboard</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Leads
                      </li>
                    </ol>
                  </nav>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-3 my-3">
            <div className="col-md-12">
              <div class="d-flex justify-content-between">
                <div className="wrap">
                  <div className="search">
                    <input
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Search"
                      style={{ borderRadius: "7px", height: "35px" }}
                    />
                    {/* <button type="submit" className="searchButton">
                      Search
                    </button> */}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="me-2">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleSelectChange}
                    >
                      <option value="">Assign Lead</option>

                      {salesData.data && salesData.data.length > 0
                        ? salesData.data.map((sale) => (
                          <option
                            key={sale._id}
                            value={`${sale._id}:${sale.first_name} ${sale.last_name}`}
                          >
                            {sale.first_name} {sale.last_name}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                  <div>
                    <button className="buttonAdd" onClick={handleButtonClick}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="row tab-content">
                <div
                  id="list-view"
                  className="tab-pane fade active show col-lg-12"
                >
                  <div className="card">
                    <div className="card-header">
                      <DataTable
                        customStyles={tableCustomStyles}
                        className="custom-data-table saleAdminManage-data-table"
                        columns={columns}
                        data={data}
                        // pagination // Enable pagination
                        // paginationTotalRows={data.length}
                        // onChangePage={handlePageChange}
                        // paginationPerPage={perPage}
                        // paginationRowsPerPageOptions={[10, 20, 30]} // Customize rows per page options
                        // onChangeRowsPerPage={handlePerPageChange}
                        paginationPerPageLabel="Rows per page"
                        selectableRows
                        onSelectedRowsChange={({ selectedRows }) => {
                          setSelectedLeadIds(
                            selectedRows.map((row) => row._id)
                          );
                        }}
                      />

                      {/* Pagination */}
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span>Rows per page:</span>
                        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                        </select>
                        <span>{`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage)} of ${totalRows}`}</span>
                        <div>
                          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            Previous Page
                          </button>
                          <button onClick={() => handlePageChange(currentPage + 1)} disabled={((currentPage - 1) * itemsPerPage + 1) >= totalRows}>
                            Next Page
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas
        className="LeadBox scrolldiv"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <div>
          <Offcanvas.Header closeButton>
            {id == "" ? (
              <Offcanvas.Title bsPrefix="titlestl">Add Lead</Offcanvas.Title>
            ) : (
              <Offcanvas.Title bsPrefix="titlestl">Update Lead</Offcanvas.Title>
            )}
          </Offcanvas.Header>
          <Offcanvas.Body bsPrefix="offcanvaspadingbody">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body modalpadding">
                <div className="row">
                  <div className="col-md-12 mb-2 bg-color">
                    Basic Information
                  </div>
                  <input type="hidden" name="_id"  {...register('_id')} />
                  <div className="col-md-12">
                    <label>Name</label>
                  </div>
                  <div className="form-group col-md-4">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="prefix"
                      {...register('prefix', { required: 'Please select a Name' })}
                    >
                      <option value="">Select</option>
                      <option value="Mr.">Mr. </option>
                      <option value="Ms.">Ms.</option>
                      <option value="Mrs.">Mrs.</option>
                    </select>
                    {errors.prefix && (
                      <div className="error-message">
                        {errors.prefix.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <input
                      type="text"
                      placeholder="First name"
                      className="form-control"
                      name="first_name"
                      {...register('first_name', { required: 'Please enter first name' })}

                    />
                    {errors.first_name && (
                      <div className="error-message">
                        {errors.first_name.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <input
                      type="text"
                      placeholder="Last name"
                      className="form-control"
                      name="last_name"
                      {...register('last_name', { required: 'Please enter last name' })}

                    />
                    {errors.last_name && (
                      <div className="error-message">
                        {errors.last_name.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label>Gender</label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="gender"
                      {...register('gender', { required: 'Please select gender' })}
                    >
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.gender && (
                      <div className="error-message">
                        {errors.gender.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <label>Date of birth</label>
                    <br />
                    <input
                      type="date"
                      name="DOB"
                      className={`form-control ${errors.DOB ? 'is-invalid' : ''}`}
                      onChange={(e) => {
                        register('DOB', {
                          max: {
                            value: '2015-12-31',
                            message: 'Date must be on or before 2015-12-31',
                          },
                        });
                        handleDOBChange(e);
                      }}
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <label>Age</label>
                    <br />
                    <input
                      type="text"
                      name="age"
                      className="form-control"
                      {...register('age')}
                      disabled
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Email</label>
                    <br />
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      {...register('email', { required: 'Please enter the email' })}
                    />
                    {errors.email && (
                      <div className="error-message">
                        {errors.email.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label>Education</label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="education"
                      {...register('education', { required: 'Please select the education' })}
                    >
                      <option value="">Select</option>
                      <option value="10 TH">10 TH</option>
                      <option value="12 TH">12 TH</option>
                      <option value="Gradute">Gradute</option>
                      <option value="Diploma">Diploma</option>
                      <option value="Post Gradute">Post Gradute</option>
                      <option value="Post Gradute and Above">Post Gradute and Above</option>

                    </select>
                    {errors.education && (
                      <div className="error-message">
                        {errors.education.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label>Contact No.</label>
                    <br />
                    <PhoneInput
                      country={'in'} // Set default country code
                      onChange={handlePhoneInputChange}
                      inputProps={{
                        name: 'contact_number',
                        onBlur: () => register('contact_number', { required: 'Please enter the contact number' }), // onBlur registration
                        className: `form-control ${errors.contact_number ? 'is-invalid' : ''}`,
                      }}
                    />
                    {errors.contact_number && (
                      <div className="error-message">{errors.contact_number.message}</div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label> Alt Contact No.</label>
                    <br />
                    <PhoneInput
                      country={'in'}
                      onChange={handleAlternatePhoneInputChange}
                      inputProps={{
                        name: 'alt_contact_number',
                        onBlur: () => register('alt_contact_number'), // onBlur registration
                        className: `form-control ${errors.alt_contact_number ? 'is-invalid' : ''}`,
                      }}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Country</label>
                    <br />
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      name="country"
                      {...register('country', { required: 'Please select the country' })}
                      onChange={(event) => getState(event.target.value)}
                    >
                      <option value="">
                        Select
                      </option>
                      {countries.map((country) => (
                        <option value={country.short_name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {errors.country && (
                      <div className="error-message">
                        {errors.country.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label>State</label>
                    <br />
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      name="state"
                      {...register('state', { required: 'Please select the state' })}
                    >
                      <option value="">
                        Select
                      </option>
                      {states.map((state) => (
                        <option value={state.short_name}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {errors.state && (
                      <div className="error-message">
                        {errors.state.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 my-3 bg-color">
                    Lead Information
                  </div>

                  <div className="form-group col-md-12">
                    <label>Notes</label>
                    <br />
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea"
                        name="note"
                        {...register('note', { required: 'Please enter the note' })}
                      ></textarea>
                      <label htmlFor="floatingTextarea">Notes</label>
                    </div>
                    {errors.note && (
                      <div className="error-message">
                        {errors.note.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label>Upload File(s)</label>
                    <br />
                    <input
                      type="file"
                      className="form-control"
                      // accept=".pdf,.doc,.docx,.txt,.jpg,.png,.jpeg"
                      accept=".jpg, .png, .jpeg"
                      name="Upload_File" // Add a name for the file input if needed
                      onChange={handleFileChange}
                      multiple
                    />
                    {/* {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} */}
                  </div>

                  <div className="form-group col-md-6"></div>

                  <div className="form-group col-md-6">
                    <label>Have you taken Any Proficiency Test? </label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="Proficiency_Test"
                      {...register('Proficiency_Test', { required: 'Please select the have you taken Any Proficiency Test?' })}
                    >
                      <option value="" >
                        Select
                      </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    {errors.Proficiency_Test && (
                      <div className="error-message">
                        {errors.Proficiency_Test.message}
                      </div>
                    )}
                  </div>

                  {proficiencyTestValue == "yes" && (
                    <>
                      <div className="form-group col-md-6">
                        <label>If Yes?</label>
                        <br />
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="proficiencyLevel"
                          {...register('proficiencyLevel', { required: 'Please select proficiency level' })}
                        >
                          <option value="" disabled>
                            Select proficiency level
                          </option>
                          <option value="IELTS">IELTS</option>
                          <option value="IELTS ACEDEMIC">IELTS ACEDEMIC</option>
                          <option value="IELTS GENERAL">IELTS GENERAL</option>
                          <option value="PTE">PTE</option>
                          <option value="DUOLINGO">DUOLINGO</option>
                          <option value="CELPIP">CELPIP</option>
                          <option value="PTE CORE">PTE CORE</option>
                        </select>
                        {errors.proficiencyLevel && (
                          <div className="error-message">{errors.proficiencyLevel.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Reading (R) </label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          max={200}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="Reading_score"
                          {...register('Reading_score', {
                            required: 'Please enter Reading score',
                            min: { value: 1, message: 'Value must be at least 1' },
                            max: { value: 200, message: 'Value must be at most 200' }
                          })}
                          className="form-control"
                        />
                        {errors.Reading_score && (
                          <div className="error-message">{errors.Reading_score.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Writing (W) </label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          max={200}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="Writing_score"
                          {...register('Writing_score', {
                            required: 'Please enter Writing score', min: { value: 1, message: 'Value must be at least 1' },
                            max: { value: 200, message: 'Value must be at most 200' }
                          })}
                          className="form-control"
                        />
                        {errors.Writing_score && (
                          <div className="error-message">{errors.Writing_score.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Listening (L)</label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          max={200}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="Listening_score"
                          {...register('Listening_score', {
                            required: 'Please enter Listening score', min: { value: 1, message: 'Value must be at least 1' },
                            max: { value: 200, message: 'Value must be at most 200' }
                          })}
                          className="form-control"
                        />
                        {errors.Listening_score && (
                          <div className="error-message">{errors.Listening_score.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Speaking (S)</label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          max={200}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="Speaking_score"
                          {...register('Speaking_score', {
                            required: 'Please enter speaking', min: { value: 1, message: 'Value must be at least 1' },
                            max: { value: 200, message: 'Value must be at most 200' }
                          })}
                          className="form-control"
                        />
                        {errors.Speaking_score && (
                          <div className="error-message">{errors.Speaking_score.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Over All</label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="over_all"
                          {...register('over_all', {
                            required: 'Please enter over all', min: { value: 1, message: 'Value must be at least 1' }
                          })}
                          className="form-control"
                        />
                        {errors.over_all && (
                          <div className="error-message">{errors.over_all.message}</div>
                        )}
                      </div>
                    </>
                  )}

                  <div className="form-group col-md-4">
                    <label>Lead Type</label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="Lead_Type"
                      {...register('Lead_Type', { required: 'Please select lead type' })}
                    >
                      <option value="">Select</option>
                      {leadTypeList.data && leadTypeList.data.length > 0 ? (
                        leadTypeList.data.map((val, key) => (
                          <option value={val.lead_type}>{val.lead_type}</option>
                        ))
                      ) : null}
                    </select>
                    {errors.Lead_Type && (
                      <div className="error-message">{errors.Lead_Type.message}</div>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <label>Lead Source</label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="Lead_Source"
                      {...register('Lead_Source', { required: 'Please select lead source' })}
                    >
                      <option value="">Select</option>
                      {leadSourceList.data && leadSourceList.data.length > 0 ? (
                        leadSourceList.data.map((val, key) => (
                          <option value={val.lead_source}>{val.lead_source}</option>
                        ))
                      ) : null}
                    </select>
                    {errors.Lead_Source && (
                      <div className="error-message">{errors.Lead_Source.message}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center">

                {id == "" ? (
                  loading ? (
                    <button className="btn btn-primary me-2" disabled>
                      Loading....
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary me-2">
                      Save
                    </button>
                  )
                ) : (
                  loading ? (
                    <button className="btn btn-primary me-2" disabled>
                      Loading....
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary me-2">
                      Update
                    </button>
                  )
                )}
                <button type="reset" onClick={handleClose} className="btn btn-secondary">
                  Cancel
                </button>
              </div>
            </form>
          </Offcanvas.Body></div>
      </Offcanvas>

      <div>
        <Offcanvas
          className="profileCanvas"
          show={followup}
          onHide={followupClose}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              {apiResponse && apiResponse.data && (
                <div className="profilFlex">
                  <div className="prbg">
                    <i className="fa-solid fa-user" />
                  </div>
                  <h6 className="prftitle">{apiResponse.data.first_name}  {apiResponse.data.last_name} </h6>
                </div>
              )}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form action="">
              {apiResponse && apiResponse.data && (
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Name</h6>
                        <p>{apiResponse.data.first_name}</p>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                    <div className="namedetail">
                      <h6>Name</h6>
                      <p>Areon Foster</p>
                    </div>
                  </div> */}
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Gender</h6>
                        <p>{apiResponse.data.gender}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Email</h6>
                        <p>{apiResponse.data.email}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Age</h6>
                        <p>{apiResponse.data.age == "undefined" ? "" : apiResponse.data.age}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Contact No.</h6>
                        <p>{apiResponse.data.contact_number}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Alt. Contact No. </h6>
                        <p>{apiResponse.data.alt_contact_number}</p>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Country</h6>
                        <p>{apiResponse.data.country_name}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>State</h6>
                        <p>{apiResponse.data.state_name}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Education</h6>
                        <p>{apiResponse.data.education}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Note</h6>
                        <p>{apiResponse.data.note}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Services</h6>
                        <p>{apiResponse.data.Services}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="namedetail">
                        <h6>Upload File</h6>
                        {apiResponse.data.Upload_File == null ? "" : <img src={`https://api.checkcheckservices.in/public/${apiResponse.data.Upload_File}`} style={{ width: "150px" }} />}
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="rowv mt-2">
                    <div className="col-md-12">
                      <div className="mytabpill">
                        <ul
                          className="nav nav-pills nav-justified my-2"
                          id="myTabs"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="commentary-tab"
                              data-bs-toggle="pill"
                              href="#Commentary"
                              role="tab"
                            >
                              Details
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="videos-tab"
                              data-bs-toggle="pill"
                              href="#Videos"
                              role="tab"
                            >
                              Follow-ups
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="events-tab"
                              data-bs-toggle="pill"
                              href="#Events"
                              role="tab"
                            >
                              Payment
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="filing-tab"
                              data-bs-toggle="pill"
                              href="#Filing"
                              role="tab"
                            >
                              Filing Process
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane fade show active"
                            id="Commentary"
                            role="tabpanel"
                          >
                            <div className="row">
                              <div className="col-md-12 my-3">
                                <div className="accrordiondiv">
                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          Lead Information
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body">
                                          <table className="table">
                                            <tbody>
                                              <tr>
                                                <th>Lead Status</th>
                                                <td>{apiResponse.data.status_convert[apiResponse.data.status_convert.length - 1]}</td>
                                              </tr>

                                              <tr>
                                                <th>Lead Type</th>
                                                <td>{apiResponse.data.Lead_Type}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Source</th>
                                                <td>{apiResponse.data.Lead_Source}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Assign Sales</th>
                                                <td>{apiResponse.data.Lead_AssignName == null ? "N/A" : apiResponse.data.Lead_AssignName}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Assign Filling Manager</th>
                                                <td>{apiResponse.data.Filing_ManagerName == null ? "N/A" : apiResponse.data.Filing_ManagerName}</td>
                                              </tr>
                                              <tr>
                                                <th>Lead Assign Filling Team</th>
                                                <td>{apiResponse.data.Filing_TeamName == null ? "N/A" : apiResponse.data.Filing_TeamName}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="Videos"
                            role="tabpanel"
                          >
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="table-responsive tablebg">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <th>Followup Status</th>
                                        <th>Follow up Date</th>
                                        <th>Note</th>
                                        <th>Next Follow up Date</th>
                                      </tr>
                                      {followUpStatus.Followup_Date_fiiling_team.slice().reverse().map((date, index) => (
                                        <tr key={index}>
                                          <td>{followUpStatus.Filing_Team_Status[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
                                          <td>{formatLeadDate(date)} {formatLeadTime(date)}</td>
                                          <td>{followUpStatus.Notes_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]}</td>
                                          <td>{followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date_fiiling_team[followUpStatus.Followup_Date_fiiling_team.length - 1 - index]) : ""}</td>
                                        </tr>
                                      ))}
                                      {followUpStatus.Followup_Date.slice().reverse().map((formattedDate, index) => (
                                        <tr key={index}>
                                          <td>{followUpStatus.status_convert[followUpStatus.Followup_Date.length - 1 - index]}</td>
                                          <td>{formatLeadDate(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])} {formatLeadTime(followUpStatus.Followup_Date[followUpStatus.Followup_Date.length - 1 - index])}</td>
                                          <td>{followUpStatus.Notes_sales[followUpStatus.Followup_Date.length - 1 - index]}</td>
                                          <td>{followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index] ? formatLeadDate(followUpStatus.Next_Followup_Date[followUpStatus.Followup_Date.length - 1 - index]) : ""}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="Events"
                            role="tabpanel"
                          >
                            <div className="row justify-content-center">
                              <div className="col-md-3">
                                <div className="namedetail">
                                  <h6>Total Payment</h6>
                                  {apiResponse.data.status_convert.includes('Convert') ? (
                                    <p>{followUpStatus.Total_Amount.slice().reverse().map((formattedDate, index) => (
                                      <tr key={index}>
                                        <td>${formattedDate}</td>
                                      </tr>
                                    ))}</p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="namedetail">
                                  <h6>Payment Received</h6>
                                  {apiResponse.data.status_convert.includes('Convert') ? (
                                    <p>{followUpStatus.Amount_Paid.slice().reverse().map((formattedDate, index) => (
                                      <tr key={index}>
                                        <td>${formattedDate}</td>
                                      </tr>
                                    ))}</p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="namedetail">
                                  <h6>Remaining Payment</h6>
                                  {apiResponse.data.status_convert.includes('Convert') ? (
                                    <p>{followUpStatus.Amount_Due.slice().reverse().map((formattedDate, index) => (
                                      <tr key={index}>
                                        <td>${formattedDate}</td>
                                      </tr>
                                    ))}</p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="table-responsive tablebg">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Proof</th>
                                      </tr>

                                      {apiResponse.data.status_convert.includes('Convert') ? (
                                        followUpStatus.Payment_Proof_Date.slice().reverse().map((formattedDate, index) => (
                                          <tr key={index}>
                                            <td>{formatLeadDate(formattedDate)}</td>
                                            <td>{followUpStatus.Amount_Paid[followUpStatus.Payment_Proof_Date.length - 1 - index]}</td>
                                            <td>
                                              <a href={`https://api.checkcheckservices.in/public/${followUpStatus.Upload_Payment_Proof[followUpStatus.Payment_Proof_Date.length - 1 - index]}`} download={`PaymentProof-${index}`} target="_blank" >
                                                View
                                              </a>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <p></p>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="Filing"
                            role="tabpanel"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12 my-3">
                                  <div className="accrordiondiv">
                                    <div
                                      className="accordion"
                                      id="accordionExample2"
                                    >
                                      <div className="accordion-item">
                                        <h2
                                          className="accordion-header"
                                          id="headingOne"
                                        >
                                          <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                          >
                                            Services
                                          </button>
                                        </h2>
                                        <div
                                          id="collapseOne"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="headingOne"
                                          data-bs-parent="#accordionExample2"
                                        >
                                          <div className="accordion-body">
                                            {serviceName.data && serviceName.data.length > 0 ? (
                                              serviceName.data.map((val, key) => (
                                                <div className="form-check">
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    checked={apiResponse.data.Services === val.service_name}

                                                  />
                                                  <label
                                                    className="form-check-label forcheckfont"
                                                    htmlfor="flexRadioDefault1"
                                                  >
                                                    {val.service_name}
                                                  </label>
                                                </div>
                                              ))
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="accordion-item">
                                        <h2
                                          className="accordion-header"
                                          id="headingTwo"
                                        >
                                          <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                          >
                                            Filing Status
                                          </button>
                                        </h2>
                                        <div
                                          id="collapseTwo"
                                          className="accordion-collapse collapse"
                                          aria-labelledby="headingTwo"
                                          data-bs-parent="#accordionExample2"
                                        >
                                          <div className="accordion-body accrfp">
                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="caseInitiated"
                                                checked={caseInitiatedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>Case Initiated</h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="fileIntiated"
                                                checked={fileInitiatedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  File Initiated or docs checklist
                                                  sent
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="docsReceived"
                                                checked={docReceivedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  Docs received & Pending docs
                                                  sent{" "}
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="sopprepration"
                                                checked={soppreprationChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  Sop Or letters prepration &
                                                  Forms prep
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="sopletters"
                                                checked={sopLetterChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  SOP or Letters sent to client{" "}
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="confirmrecieved"
                                                checked={confirmReceivedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  Confirmation received on SOP,
                                                  letters and docs, Forms
                                                  confirmation{" "}
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="filesent"
                                                checked={fileSentChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>
                                                  File sent for Upload or
                                                  submission
                                                </h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="visaapproved"
                                                checked={visaApprovedChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>Visa approved</h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                            <div className="form-check frmch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                defaultValue
                                                id="flexCheckDefault"
                                                name="visarefusal"
                                                checked={visaRefusalChecked}
                                                disabled
                                              />
                                              <div className="caseinitiate">
                                                <h6>Visa refusal</h6>
                                                {/* <p>03 Nov 23, 01:00 PM</p> */}
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}
