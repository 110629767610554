import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useFormik } from "formik";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import Swal from "sweetalert2";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import host from "./utils";
// import 'bootstrap/dist/css/bootstrap.min.css';

function OffCanvasExample({ name, ...props }) {
  const [imageFile, setImageFile] = useState(null);
  const [defaultCountryNumber, setDefaultCountryNumber] = useState('in');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [show, setShow] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState('in'); // Set default country to India
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [leadTypeList, setLeadTypeList] = useState([]);
  const [leadSourceList, setLeadSourceList] = useState([]);
  const [loading, setLoading] = useState(false);

  const MySwal = withReactContent(Swal);

  const navigate = useNavigate();

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userId");
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('pre_sales_id', getUserIdFromLocalStorage());
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    try{
      const response = await axios.post(`${host}/AddLead`, formData);
      setLoading(false);
      if (response.data.error == true) {
        MySwal.fire({
          icon: "warning",
          title: "Warning!",
          text: `${response.data.message}`,
        });
      } else {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: `${response.data.message}`,
        });
        handleClose();
        // window.location = "http://192.168.29.47:3001/leadmanage";
        window.location = "https://checkcheckservices.in/leadmanage";
      }
    } catch (error) {
      setLoading(false);
      MySwal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while adding the lead. Please try again.",
      });
    }
  };

  const handleDOBChange = (e) => {
    const dob = e.target.value;
    setValue("DOB", dob);
    const age = calculateAge(dob);
    setValue("age", age);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handlePhoneInputChange = (value, country, e, formattedValue) => {
    setValue('contact_number', formattedValue || value);
  };

  const handleAlternatePhoneInputChange = (value, country, e, formattedValue) => {
    setValue('alt_contact_number', formattedValue || value);
  };

  const handleFileChange = (event) => {
    setValue('Upload_File', event.target.files[0]);
  };

  const proficiencyTestValue = watch('Proficiency_Test');

  const handleClose = () => {
    reset();
    setShow(false);
    setStates([]);
    setLoading(false);
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchCountryCode();
    fetchCountries();
    fetchLeadType();
    fetchLeadSource();
  }, []);

  const fetchCountryCode = async () => {
    try {
      const response = await fetch('https://ipinfo.io/json');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data && data.country) {
        setDefaultCountryNumber(data.country);
      } else {
        console.error('Invalid response format:', data);
      }
    } catch (error) {
      console.error('Error fetching country code:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${host}/list-country`);
      if (response.data.error == false) {
        setCountries(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchLeadType = async () => {
    try {
      const response = await axios.get(`${host}/listlead-type`);
      if (response.data.error == false) {
        setLeadTypeList(response.data);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchLeadSource = async () => {
    try {
      const response = await axios.get(`${host}/listlead-source`);
      if (response.data.error == false) {
        setLeadSourceList(response.data);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const getState = async (event, state) => {
    const data = {
      country_code: event
    }
    const response = await axios.post(`${host}/find-countrycode`, data);
    console.log(response);
    if (response.data.error == false) {
      if (state == '') {
        setStates(response.data.data);
      } else {
        response.data.data.forEach((val) => {
          if (state == val.short_name) {
            setValue('state', val.short_name)
          }
        })
        setStates(response.data.data);
      }
    }
  };

  return (
    <>

      <div className="col-md-7 text-end">
        <Button onClick={handleShow} className="buttonAdd">
          {name}
        </Button>
      </div>

      <Offcanvas
        className="LeadBox scrolldiv"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <div>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title bsPrefix="titlestl"> Add Lead</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body bsPrefix="offcanvaspadingbody">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body modalpadding">
                <div className="row">
                  <div className="col-md-12 mb-2 bg-color">
                    Basic Information
                  </div>
                  <div className="col-md-12">
                    <label>Name</label>
                  </div>
                  <div className="form-group col-md-4">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="prefix"
                      {...register('prefix', { required: 'Please select a Name' })}
                    >
                      <option value="">Select</option>
                      <option value="Mr.">Mr. </option>
                      <option value="Ms.">Ms.</option>
                      <option value="Mrs.">Mrs.</option>
                    </select>
                    {errors.prefix && (
                      <div className="error-message">
                        {errors.prefix.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <input
                      type="text"
                      placeholder="First name"
                      className="form-control"
                      name="first_name"
                      {...register('first_name', { required: 'Please enter first name' })}

                    />
                    {errors.first_name && (
                      <div className="error-message">
                        {errors.first_name.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <input
                      type="text"
                      placeholder="Last name"
                      className="form-control"
                      name="last_name"
                      {...register('last_name', { required: 'Please enter last name' })}

                    />
                    {errors.last_name && (
                      <div className="error-message">
                        {errors.last_name.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label>Gender</label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="gender"
                      {...register('gender', { required: 'Please select gender' })}
                    >
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.gender && (
                      <div className="error-message">
                        {errors.gender.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <label>Date of birth</label>
                    <br />
                    <input
                      type="date"
                      name="DOB"
                      className={`form-control ${errors.DOB ? 'is-invalid' : ''}`}
                      onChange={(e) => {
                        register('DOB', {
                          max: {
                            value: '2015-12-31',
                            message: 'Date must be on or before 2015-12-31',
                          },
                        });
                        handleDOBChange(e);
                      }}
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <label>Age</label>
                    <br />
                    <input
                      type="text"
                      name="age"
                      className="form-control"
                      {...register('age')}
                      disabled
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Email</label>
                    <br />
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      {...register('email', { required: 'Please enter the email' })}
                    />
                    {errors.email && (
                      <div className="error-message">
                        {errors.email.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label>Education</label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="education"
                      {...register('education', { required: 'Please select the education' })}
                    >
                      <option value="">Select</option>
                      <option value="10 TH">10 TH</option>
                      <option value="12 TH">12 TH</option>
                      <option value="Gradute">Gradute</option>
                      <option value="Diploma">Diploma</option>
                      <option value="Post Gradute">Post Gradute</option>
                      <option value="Post Gradute and Above">Post Gradute and Above</option>

                    </select>
                    {errors.education && (
                      <div className="error-message">
                        {errors.education.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label>Contact No.</label>
                    <br />
                    <PhoneInput
                      country={'in'}
                      onChange={handlePhoneInputChange}
                      inputProps={{
                        name: 'contact_number',
                        onBlur: () => register('contact_number'), // onBlur registration
                        className: `form-control ${errors.contact_number ? 'is-invalid' : ''}`,
                      }}
                    />
                    {errors.contact_number && (
                      <div className="error-message">{errors.contact_number.message}</div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label> Alt Contact No.</label>
                    <br />
                    <PhoneInput
                      country={'in'}
                      onChange={handleAlternatePhoneInputChange}
                      inputProps={{
                        name: 'alt_contact_number',
                        onBlur: () => register('alt_contact_number'), // onBlur registration
                        className: `form-control ${errors.alt_contact_number ? 'is-invalid' : ''}`,
                      }}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Country</label>
                    <br />
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      name="country"
                      {...register('country', { required: 'Please select the country' })}
                      onChange={(event) => getState(event.target.value)}
                    >
                      <option value="">
                        Select
                      </option>
                      {countries.map((country) => (
                        <option value={country.short_name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {errors.country && (
                      <div className="error-message">
                        {errors.country.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label>State</label>
                    <br />
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      name="state"
                      {...register('state', { required: 'Please select the state' })}
                    >
                      <option value="">
                        Select
                      </option>
                      {states.map((state) => (
                        <option value={state.short_name}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {errors.state && (
                      <div className="error-message">
                        {errors.state.message}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 my-3 bg-color">
                    Lead Information
                  </div>

                  <div className="form-group col-md-12">
                    <label>Notes</label>
                    <br />
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea"
                        name="note"
                        {...register('note', { required: 'Please enter the note' })}
                      ></textarea>
                      <label htmlFor="floatingTextarea">Notes</label>
                    </div>
                    {errors.note && (
                      <div className="error-message">
                        {errors.note.message}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label>Upload File</label>
                    <br />
                    <input
                      type="file"
                      className="form-control"
                      name="Upload_File" // Add a name for the file input if needed
                      onChange={handleFileChange}
                      accept=".jpg, .png, .jpeg"
                    />
                  </div>

                  <div className="form-group col-md-6"></div>

                  <div className="form-group col-md-6">
                    <label>Have you taken Any Proficiency Test? </label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="Proficiency_Test"
                      {...register('Proficiency_Test', { required: 'Please select the have you taken Any Proficiency Test?' })}
                    >
                      <option value="" >
                        Select
                      </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    {errors.Proficiency_Test && (
                      <div className="error-message">
                        {errors.Proficiency_Test.message}
                      </div>
                    )}
                  </div>

                  {proficiencyTestValue == "yes" && (
                    <>
                      <div className="form-group col-md-6">
                        <label>If Yes?</label>
                        <br />
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="proficiencyLevel"
                          {...register('proficiencyLevel', { required: 'Please select proficiency level' })}
                        >
                          <option value="" disabled>
                            Select proficiency level
                          </option>
                          <option value="IELTS">IELTS</option>
                          <option value="IELTS ACEDEMIC">IELTS ACEDEMIC</option>
                          <option value="IELTS GENERAL">IELTS GENERAL</option>
                          <option value="PTE">PTE</option>
                          <option value="DUOLINGO">DUOLINGO</option>
                          <option value="CELPIP">CELPIP</option>
                          <option value="PTE CORE">PTE CORE</option>
                        </select>
                        {errors.proficiencyLevel && (
                          <div className="error-message">{errors.proficiencyLevel.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Reading (R) </label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          max={200}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="Reading_score"
                          {...register('Reading_score', {
                            required: 'Please enter Reading score', min: { value: 1, message: 'Value must be at least 1' },
                            max: { value: 200, message: 'Value must be at most 200' }
                          })}
                          className="form-control"
                        />
                        {errors.Reading_score && (
                          <div className="error-message">{errors.Reading_score.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Writing (W) </label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          max={200}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="Writing_score"
                          {...register('Writing_score', {
                            required: 'Please enter Writing score', min: { value: 1, message: 'Value must be at least 1' },
                            max: { value: 200, message: 'Value must be at most 200' }
                          })}
                          className="form-control"
                        />
                        {errors.Writing_score && (
                          <div className="error-message">{errors.Writing_score.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Listening (L)</label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          max={200}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="Listening_score"
                          {...register('Listening_score', {
                            required: 'Please enter Listening score', min: { value: 1, message: 'Value must be at least 1' },
                            max: { value: 200, message: 'Value must be at most 200' }
                          })}
                          className="form-control"
                        />
                        {errors.Listening_score && (
                          <div className="error-message">{errors.Listening_score.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Speaking (S)</label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          max={200}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="Speaking_score"
                          {...register('Speaking_score', {
                            required: 'Please enter speaking', min: { value: 1, message: 'Value must be at least 1' },
                            max: { value: 200, message: 'Value must be at most 200' }
                          })}
                          className="form-control"
                        />
                        {errors.Speaking_score && (
                          <div className="error-message">{errors.Speaking_score.message}</div>
                        )}
                      </div>

                      <div className="form-group col-md-6">
                        <label>Over All</label>
                        <br />
                        <input
                          type="number"
                          min={1}
                          step="0.01" // Allows up to two decimal places
                          pattern="\d+(\.\d{1,2})?" // Allows only up to two decimal places
                          name="over_all"
                          {...register('over_all', {
                            required: 'Please enter over all', min: { value: 1, message: 'Value must be at least 1' }
                          })}
                          className="form-control"
                        />
                        {errors.over_all && (
                          <div className="error-message">{errors.over_all.message}</div>
                        )}
                      </div>
                    </>
                  )}

                  <div className="form-group col-md-4">
                    <label>Lead Type</label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="Lead_Type"
                      {...register('Lead_Type', { required: 'Please select lead type' })}
                    >
                      <option value="">Select</option>
                      {leadTypeList.data && leadTypeList.data.length > 0 ? (
                        leadTypeList.data.map((val, key) => (
                          <option value={val.lead_type}>{val.lead_type}</option>
                        ))
                      ) : null}
                    </select>
                    {errors.Lead_Type && (
                      <div className="error-message">{errors.Lead_Type.message}</div>
                    )}
                  </div>

                  <div className="form-group col-md-4">
                    <label>Lead Source</label>
                    <br />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="Lead_Source"
                      {...register('Lead_Source', { required: 'Please select lead source' })}
                    >
                      <option value="">Select</option>
                      {leadSourceList.data && leadSourceList.data.length > 0 ? (
                        leadSourceList.data.map((val, key) => (
                          <option value={val.lead_source}>{val.lead_source}</option>
                        ))
                      ) : null}
                    </select>
                    {errors.Lead_Source && (
                      <div className="error-message">{errors.Lead_Source.message}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-center">
                {loading ? (
                  <button className="btn btn-primary me-2" disabled>
                    Loading....
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary me-2">
                    Save
                  </button>
                )}
                <button type="reset" onClick={handleClose} className="btn btn-secondary">
                  Cancel
                </button>
              </div>
            </form>
          </Offcanvas.Body>
        </div>
      </Offcanvas>
    </>
  );
}

export default OffCanvasExample;
